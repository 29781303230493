<template>
	<div class="login_wrapper">
		<img class="login_l" src="../../assets/img/bg1.jpeg" alt="" />
		<div class="login_r">
			<div class="form" v-if="now=='login'">
				<div class="tabs">
					<span @click="changTab(index)" :class="activeName==index?'check_item':'tab_item'"
						v-for="(item,index) in tabList" :key="index">
						{{item}}
					</span>
				</div>
				<el-input type="number" maxlength="11" oninput="if(value.length>11)value=value.slice(0,11)"
					v-model="loginInfo.phone" placeholder="请输入手机号"></el-input>
				<el-input v-if="activeName==0" v-model="loginInfo.password" show-password
					placeholder="请输入密码"></el-input>
				<div class="flex align-center ewm_wrapper" v-else>
					<el-input style="flex: 1;" v-model="loginInfo.code" placeholder="请输入验证码"></el-input>
					<div @click="getCode()">
						<span class="blue hand" v-if="codeShow">获取验证码</span>
						<span v-else class="blue hand">重新发送({{count}}s)</span>
					</div>

				</div>
				<div class="flex margin-top-sm align-center just-between">
					<span @click="changNow('forget')" style="cursor: pointer;" class="text-gray">忘记密码</span>
					<span @click="changNow('register')" class="red hand">注册账号</span>
				</div>
				<el-button type="primary" class="login_btn" round @click="loginSub()">登录</el-button>
				<el-checkbox v-model="checked">我已阅读并同意
					<span class="blue" @click="open(2)">《用户协议》</span>和
					<span class="blue" @click="open(3)">《隐私政策》</span>
				</el-checkbox>
			</div>
			<div class="form2" v-if="now=='register'">

				<div class="register_title">
					注册账号
				</div>

				<el-select style="width: 100%;" @change="enterChange" v-model="registerInfo.enterpriseId"
					placeholder="所属县">
					<el-option v-for="item in enterList" :key="item.deptId" :label="item.deptName" :value="item.deptId">
					</el-option>
				</el-select>
				<el-select :disabled="isDepart" style="width: 100%;" v-model="registerInfo.departmentId"
					placeholder="所属企业" @change="departChange">
					<el-option v-for="item in departList" :key="item.deptId" :label="item.deptName"
						:value="item.deptId">
					</el-option>
				</el-select>
				<el-select :disabled="isworkshop" style="width: 100%;" v-model="registerInfo.workshopId"
					placeholder="所属车间" @change="workChange">
					<el-option v-for="item in workshopList" :key="item.deptId" :label="item.deptName"
						:value="item.deptId">
					</el-option>
				</el-select>
				<el-select :disabled="isteam" style="width: 100%;" v-model="registerInfo.teamId" placeholder="所属小组">
					<el-option v-for="item in teamList" :key="item.deptId" :label="item.deptName" :value="item.deptId">
					</el-option>
				</el-select>
				<!-- <el-input v-model="registerInfo.affiliatedUnit" placeholder="请输入所属单位"></el-input> -->
				<el-input v-model="registerInfo.name" placeholder="请输入姓名"></el-input>
				<el-input oninput="if(value.length>11)value=value.slice(0,11)" type="number"
					v-model="registerInfo.phone" maxlength="11" placeholder="请输入手机号"></el-input>
				<div class="flex align-center ewm_wrapper">
					<el-input style="flex: 1;" v-model="registerInfo.code" placeholder="请输入验证码"></el-input>
					<div @click="getCode()">
						<span class="blue hand" v-if="codeShow">获取验证码</span>
						<span v-else class="blue hand">重新发送({{count}}s)</span>
					</div>
				</div>
				<el-input style="margin-top: 10px;" v-model="registerInfo.idCard" maxlength="18"
					placeholder="身份证信息"></el-input>
				<el-input v-model="registerInfo.password" show-password placeholder="请输入登录密码"></el-input>
				<div style="color:#999;font-size: 13px;text-align: left;">(密码必须由大写字母、小写字母、数字、特殊符号中的2种及以上类型组成)</div>
				<div class="flex margin-top-sm align-center just-between">
					<span @click="changNow('forget')" style="cursor: pointer;" class="text-gray">忘记密码</span>
					<span @click="changNow('login')" class="red hand">去登录</span>
				</div>
				<el-button type="primary" class="login_btn" round @click="registerSub">注册</el-button>
				<el-checkbox v-model="checked">我已阅读并同意
					<span class="blue" @click="open(2)">《用户协议》</span>和
					<span class="blue" @click="open(3)">《隐私政策》</span>
				</el-checkbox>
			</div>
			<div class="form" v-if="now=='forget'">

				<div class="register_title">
					忘记密码
				</div>
				<el-input oninput="if(value.length>11)value=value.slice(0,11)" v-model="forgetInfo.phone" maxlength="11"
					placeholder="请输入手机号"></el-input>
				<div class="flex align-center ewm_wrapper">
					<el-input style="flex: 1;" v-model="forgetInfo.code" placeholder="请输入验证码"></el-input>
					<div @click="getCode()">
						<span class="blue hand" v-if="codeShow">获取验证码</span>
						<span v-else class="blue hand">重新发送({{count}}s)</span>
					</div>
				</div>
				<el-input style="margin-top: 10px;" v-model="forgetInfo.password" show-password
					placeholder="请输入新密码"></el-input>
				<el-input v-model="forgetInfo.passwordTwo" show-password placeholder="请再次输入登录密码"></el-input>
				<div class="flex margin-top-sm align-center just-between">
					<span @click="changNow('login')" style="cursor: pointer;" class="text-gray">去登录</span>
					<span @click="changNow('register')" class="red hand">注册账号</span>
				</div>
				<el-button type="primary" class="login_btn" round @click="forgetSub">修改密码</el-button>

			</div>
			<div style="bottom: 0;position: fixed;bottom: 0;display: flex;width: 34%;
			align-content: center;
			justify-content: center;">
			<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">宁ICP备20000369号</a>
			</div>
		</div>
		<el-dialog width="18%" class="pop_style" :show-close="false" :visible.sync="ourShow">
			<span style="text-align: center;">{{agreeInfo.title}}</span>
			<div v-html="agreeInfo.richText"></div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		HttpRequest
	} from '@/utils/http';

	import {
		mapState
	} from "vuex";
	import {
		Message
	} from "element-ui";
	export default {
		data() {
			return {
				agree: '用户协议',
				agreeInfo: {},
				ourShow: false,
				isDepart: true,
				isworkshop: true,
				isteam: true,
				activeName: 0,
				now: 'login',
				tabList: ['账号登录', '验证码登录'],
				loginInfo: {
					phone: '',
					code: '',
					password: '',
					type: 2
				},
				registerInfo: {
					name: '',
					phone: '',
					code: '',
					enterpriseId: '',
					password: '',
					departmentId: '',
					idCard: '',
					affiliatedUnit: '',
					workshopId: '', //车间id
					teamId: '' //小组id
				},
				forgetInfo: {
					phone: '',
					code: '',
					password: '',
					passwordTwo: ''
				},
				timer: null, //设置计时器,
				count: "",
				codeShow: true,
				checked: false,
				enterList: [], //企业
				departList: [], //部门
				workshopList: [], //车间
				teamList: [], //小组
				enter: '', //所属企业
				depart: '', //所属部门
			}
		},
		mounted() {
			this.getEnter()
			this.getDepart()
		},
		methods: {
			changNow(e) {
				this.now = e
				this.timer = null
				this.count = ''
				this.codeShow = true
			},
			getEnter() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/login/enterpriseList"
				}).then(res => {
					this.enterList = res.data.data
				})

			},
			open(e) {
				this.ourShow = true
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/login/getOther?type=" + e
				}).then(res => {
					this.agreeInfo = res.data.data
				})

			},
			forgetSub() {
				if (this.forgetInfo.phone == '') return Message.error('请输入手机号')
				if (this.forgetInfo.code == '') return Message.error('请输入验证码')
				if (this.forgetInfo.password == '') return Message.error('请输入密码')
				if (this.forgetInfo.passwordTwo == '') return Message.error('请再次确认验证码')
				HttpRequest.getRequest({
					method: "POST",
					data: this.forgetInfo,
					url: "/app/login/forgetPassword"
				}).then(res => {
					if (res.data.code == 200) {
						this.now = 'login'
					}
				})
			},
			enterChange() {
				this.registerInfo.departmentId = ''
				if (this.registerInfo.enterpriseId != '') {

					this.isDepart = false
				}
				this.getDepart()
			},
			departChange() {
				this.registerInfo.workshopId = ''
				if (this.registerInfo.departmentId != '') {

					this.isworkshop = false
				}
				this.getWorkshop()
			},
			workChange() {
				this.registerInfo.teamId = ''
				if (this.registerInfo.workshopId != '') {

					this.isteam = false
				}
				this.getTeam()
			},
			getDepart() {
				HttpRequest.getRequest({
					method: "POST",
					url: '/app/login/departmentList?deptId=' + this.registerInfo.enterpriseId,
				}).then(res => {
					this.departList = res.data.data
				})
			},
			getWorkshop() {
				HttpRequest.getRequest({
					method: "POST",
					url: '/app/login/departmentList?deptId=' + this.registerInfo.departmentId,
				}).then(res => {
					this.workshopList = res.data.data
				})
			},
			getTeam() {
				HttpRequest.getRequest({
					method: "POST",
					url: '/app/login/departmentList?deptId=' + this.registerInfo.workshopId,
				}).then(res => {
					this.teamList = res.data.data
				})
			},
			registerSub() {
				console.log(this.registerInfo)


				if (this.registerInfo.enterpriseId == '') return Message.error('请选择所属县')
				if (this.registerInfo.departmentId == '') return Message.error('请选择所属企业')
				// if (this.registerInfo.affiliatedUnit == '') return Message.error('请输入所属单位')
				if (this.registerInfo.name == '') return Message.error('请输入姓名')
				if (this.registerInfo.phone == '') return Message.error('请输入手机号')
				if (this.registerInfo.code == '') return Message.error('请输入验证码')
				if (this.registerInfo.idCard == '') return Message.error('请输入身份证号')
				if (this.registerInfo.password == '') return Message.error('请输入登录密码')
				var passwordreg =
					/^(?!([a-zA-Z]+|[a-z\d]+|[a-z~`@#\$%\^&\*\(\)_\-\+=\{\[\}\]\|\\:;\"\'<,>\.\?\/\!]+|[A-Z\d]+|[A-Z~`@#\$%\^&\*\(\)_\-\+=\{\[\}\]\|\\:;\"\'<,>\.\?\/\!]+|[\d~`@#\$%\^&\*\(\)_\-\+=\{\[\}\]\|\\:;\"\'<,>\.\?\/\!]+)$)[a-zA-Z\d~`@#\$%\^&\*\(\)_\-\+=\{\[\}\]\|\\:;\"\'<,>\.\?\/\!]+$/;
				if (!passwordreg.test(this.registerInfo.password)) {
					Message.error('密码必须由大写字母、小写字母、数字、特殊符号中的2种及以上类型组成!')
					return
				}
				if (this.checked === false) return Message.error('请勾选用户协议和隐私政策')

				HttpRequest.getRequest({
					method: "POST",
					data: this.registerInfo,
					url: "/app/login/appRegister"
				}).then(res => {
					if (res.data.code == 200) {
						this.now = 'login'
					}
				})
			},
			changTab(e) {
				this.activeName = e
				if (e == 0) {
					this.loginInfo.type = 2
				} else {
					this.loginInfo.type = 1
				}

			},
			getCode() {
				if (this.now == 'login' && this.loginInfo.phone == '') {
					Message.error('请输入手机号')
					return
				}
				if (this.now == 'register' && this.registerInfo.phone == '') {
					Message.error('请输入手机号')
					return
				}
				if (this.now == 'forget' && this.forgetInfo.phone == '') {
					Message.error('请输入手机号')
					return
				}
				var data = {
					phone: this.now == 'register' ? this.registerInfo.phone : this.now == 'login' ? this.loginInfo
						.phone : this.forgetInfo.phone
				}
				HttpRequest.getRequest({
					method: "POST",
					data: data,
					url: "/app/login/sendCode"
				}).then(res => {
					this.codeBtn()
				})

			},
			codeBtn() {
				let TIME_COUNT = 60;
				if (!this.timer) {
					this.count = TIME_COUNT;
					// this.isGrey = true;
					this.codeShow = false;
					// this.dis = true;
					this.timer = setInterval(() => {
						if (this.count > 0 && this.count <= TIME_COUNT) {
							this.count--;
						} else {
							// this.dis = false;
							// this.isGrey = false;
							this.codeShow = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000);
				}
			},
			loginSub() {
				if (this.loginInfo.password == '' && this.activeName == 'first') return Message.error('请输入密码')
				if (this.loginInfo.code == '' && this.activeName == 'second') return Message.error('请输入验证码')
				if (this.loginInfo.phone == '') return Message.error('请输入手机号码')
				if (this.checked == false) return Message.error('请勾选用户协议和隐私政策')
				HttpRequest.getRequest({
					method: "POST",
					data: this.loginInfo,
					url: "/app/login/appLogin"
				}).then(res => {
					console.log(res)
					if (res.data.code == 200) {
						localStorage.setItem("token", res.data.data.token)
						localStorage.setItem("userInfo", JSON.stringify(res.data.data))
						this.$router.push('/')
						setTimeout(function() {
							window.location.reload();
						}, 20);

					}
				})
			},
		}
	}
</script>
<style>
	/* .top,
	.last {
		display: none !important;

	} */
</style>
<style lang="less" scoped>
	.red {
		color: #FF7777;
	}

	.check_item {
		color: #1B8AFB;
		font-size: 20px;
		font-weight: 600;
		cursor: pointer;
	}

	.tab_item {
		color: #666;
		cursor: pointer;
		font-weight: 600;
		font-size: 20px;
	}

	.login_wrapper {
		display: flex;
		height: 100vh;

		.login_l {
			width: 70%;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}

		.tabs {
			padding: 0 10px;
			margin: 30px auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.login_r {
			text-align: center;
			width: 30%;


			background-color: #fff;

			/deep/ .el-input {
				margin-bottom: 10px;
			}

			.ewm_wrapper /deep/ .el-input {
				margin: 0;
			}

			.ewm_wrapper /deep/ .el-input__inner {
				margin: 0;

			}

			/deep/.el-input__inner {
				height: 42px;
				line-height: 42px;
				border: none;
				background-color: #f2f2f2;
			}

			.ewm_wrapper {
				background-color: #f2f2f2;
				border-radius: 4px;
				padding-right: 8px;
			}

			.el-input__suffix {
				top: 12px;
			}

			.el-checkbox__inner {
				// background-color: #F2F2F2;
				border-radius: 50%;
			}

			.login_btn {
				background-color: #1B8AFB;
				width: 96%;
				height: 45px;
				border-radius: 30px;
				margin: 20px auto;
			}

			.form {
				width: 51%;
				margin: 0 auto;
				padding-top: 260px;
			}

			.form2 {
				width: 51%;
				margin: 0 auto;
				padding-top: 150px;
			}
		}
	}

	.register_title {
		text-align: center;
		margin-bottom: 30px;
		color: #1B8AFB;
		font-size: 20px;
		font-weight: 600;
	}
</style>