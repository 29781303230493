<template>
  <div class="wrap">
    <div class="header">
      <div class="tel">
        <i class="el-icon-phone-outline">客户服务热线: 400 065 5799</i>
      </div>
    </div>
    <div class="sign-wrap">
      <div class="sign-slide-box">
        <div class="logo">
          <img src="./img/zhucelogo.png" alt="" />
          <div>
            <p>找工作</p>
            <p>我要跟老板谈</p>
          </div>
        </div>
        <ul>
          <li>
            <i class="icon"></i>
            <span>沟通</span>
            <span>在线职位及时沟通</span>
          </li>
          <li>
            <i class="icon"></i>
            <span>任性选</span>
            <span>各大行业职位任你选</span>
          </li>
        </ul>
      </div>
      <div class="sign-content">
        <div class="btn-switch login-tab-switch scan-switch">
          <div class="switch-tip">扫码登录在这里</div>
          <div class="erweima"></div>
        </div>
        <div class="sign-form">
          <ul class="qiehuan">
            <li
              v-for="(v, index) in tab"
              :key="index"
              @click="ifshow = index"
              :class="{ active: ifshow == index }"
            >
              {{ v }}
            </li>
          </ul>
          <div>
            <form v-show="ifshow == 0" class="forms">
              <div class="form-row">
                <i class="icon-sign-phone"></i>
                <el-dropdown :hide-on-click="false" trigger="click">
                  <span class="el-dropdown-link">
                    +86<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="xiala">
                    <el-dropdown-item
                      v-for="(v, index) in dropdownmenu"
                      :key="index"
                      >{{ v.name
                      }}<span>+{{ v.number }}</span></el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <input
                  type="number"
                  name="phone"
                  id=""
                  class="ipt ipt-phone required"
                  placeholder="手机号"
                  v-model="tel"
                  maxlength="11"
                />
              </div>
              <div class="form-row2">
                <i class="icon-sign-phone"></i>
                <input
                  type="password"
                  name="password"
                  id=""
                  class="ipt ipt-phone required"
                  placeholder="密码"
                  v-model="password"
                />
              </div>
              <div class="confirm">
                <p class="yuan"></p>
                <p>点击按钮进行验证</p>
              </div>
              <div class="login">
                <button @click="denglu">登录</button>
              </div>
              <ul class="text-tip">
                <li>
                  <img src="./img/weixin2.png" alt="" /><span>微信登录</span>
                </li>
                <li>扫码登录</li>
               <router-link to="./zhuce"><li>免费注册</li></router-link> 
              </ul>
            </form>
            <div v-show="ifshow == 1">
              <form v-show="ifshow == 1" class="forms">
                <div class="form-row">
                  <i class="icon-sign-phone"></i>
                  <el-dropdown :hide-on-click="false" trigger="click">
                    <span class="el-dropdown-link">
                      +86<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="xiala">
                      <el-dropdown-item
                        v-for="(v, index) in dropdownmenu"
                        :key="index"
                        >{{ v.name
                        }}<span>+{{ v.number }}</span></el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                  <input
                    type="tel"
                    name="phone"
                    id=""
                    class="ipt ipt-phone required"
                    placeholder="手机号"
                    v-model="tel"
                    maxlength="11"
                  />
                </div>
                <div class="confirm">
                  <p class="yuan"></p>
                  <p>点击按钮进行验证</p>
                </div>
                <div class="duanxin">
                  <i class="el-icon-message"></i
                  ><input
                    type="number"
                    placeholder="短信验证码"
                    class="phonecode"
                    maxlength="6"
                  />
                  <span class="huixian">|</span>
                  <span class="yanzhengma" @click="huoqu">
                    {{ ms }} {{ ms != "发送验证码" ? "秒" : "" }}
                  </span>
                </div>
                <div class="login">
                  <button @click="denglu">登录</button>
                </div>
                <ul class="text-tip">
                  <li>
                    <img src="./img/weixin2.png" alt="" /><span>微信登录</span>
                  </li>
                  <li>扫码登录</li>
                  <li>免费注册</li>
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ifshow: 0,
      ms: "发送验证码",
      timer: "",
      tel: "",
      password: "",
      tab: ["密码登录", "短信登录"],
      dropdownmenu: [
        {
          name: "中国大陆",
          number: 36,
        },
        {
          name: "中国香港",
          number: "852",
        },
        {
          name: "中国台湾",
          number: "886",
        },
        {
          name: "美国",
          number: "1",
        },
        {
          name: "日本",
          number: "81",
        },
        {
          name: "英国",
          number: "44",
        },
        {
          name: "韩国",
          number: "82",
        },
        {
          name: "法国",
          number: "33",
        },
        {
          name: "俄罗斯",
          number: "7",
        },
        {
          name: "意大利",
          number: "39",
        },
        {
          name: "澳大利亚",
          number: "61",
        },
        {
          name: "新加坡",
          number: "65",
        },
        {
          name: "菲律宾",
          number: "63",
        },
        {
          name: "马来西亚",
          number: "60",
        },
        {
          name: "新西兰",
          number: "64",
        },
        {
          name: "西班牙",
          number: "34",
        },
        {
          name: "缅甸",
          number: "95",
        },
        {
          name: "毛里求斯",
          number: "230",
        },
        {
          name: "津巴布韦",
          number: "263",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    denglu() {
      // let me = this;
      if (!this.tel || !this.password) {
        alert("用户名或密码不能为空");
      } else {
        this.$axios({
          method: "post",
          url: "http://8.131.78.129:3000/boss/login", // 接口地址
          data: {
            phone: "18521721605",
            password: "666666",
          },
        })
          .then((response) => {
            console.log(response, "success"); // 成功的返回
        //     this.$store.commit("denglu", this.tel);
        this.$router.push("/start2");
          })
          .catch((error) => console.log(error, "error")); // 失败的返回
 this.$router.push("/start2");
        
      }
    },
    huoqu() {
      var that = this;
      this.timerf();
      this.timer = setInterval(function () {
        that.timerf();
      }, 1000);
    },
    timerf() {
      if (this.ms == "发送验证码") {
        this.ms = 60;
      }
      if (this.ms <= 1) {
        this.ms = "发送验证码";
        clearInterval(this.timer);
        return;
      }
      this.ms--;
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: #5dd5c8 url(./img/zhucebg.png) center bottom no-repeat;
  width: 100%;
  height: 600px;
}
.tel i {
  padding-right: 3px;
  vertical-align: middle;
}
.header {
  width: 100%;
  height: 49px;
  position: relative;
  z-index: 1005;
  .tel {
    float: right;
    height: 20px;
    padding: 60px 60px 0 0;
    line-height: 20px;
    color: #fff;
    vertical-align: middle;
    opacity: 0.7;
  }
}
.sign-wrap {
  width: 740px;
  min-height: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -250px 0 0 -370px;
}
.sign-slide-box {
  float: left;
  width: 240px;
  height: 500px;
  color: #8d92a1;
  background-color: #f8fcff;
  border-radius: 10px 0 0 10px;
  ul {
    margin: 50px 0 0 40px;
    li {
      margin-bottom: 50px;
      i {
        float: left;
        width: 25px;
        height: 42px;
        background: url(./img/sign-slide.png) no-repeat;
        background-size: 100% auto;
      }
    }
  }
  .logo {
    float: none;
    display: block;
    width: auto;
    height: 40px;
    margin: 88px 0 0 20px;
    padding: 0;
    div {
      float: right;
      position: relative;
      bottom: -1px;
      right: 65px;
      p {
        color: #00c2b3;
        line-height: 20px;
        text-align: left;
        font-size: 14px;
        margin-right: 10px;
      }
    }
    img {
      width: 42px;
      height: 42px;
      margin-right: 59px;
    }
  }
}
.sign-slide-box li:nth-of-type(4n + 1) i {
  background-position: 0 10px;
}
.sign-slide-box li > span:first-of-type {
  line-height: 22px;
  color: #8d92a1;
  font-size: 16px;
  font-weight: 700;
}
.sign-slide-box li > span {
  display: block;
  text-align: left;
  text-indent: 1em;
}
.sign-slide-box li > span:last-of-type {
  margin-top: 2px;
  line-height: 18px;
  color: #b0b4c1;
  font-size: 13px;
}
.sign-slide-box li:last-of-type {
  margin-bottom: 0 !important;
}
.sign-slide-box li:nth-of-type(4n + 2) i {
  background-position: 0 -45px;
}
.sign-content {
  position: relative;
  margin-left: 240px;
  height: 500px;
  background-color: #fff;
  border-radius: 0 10px 10px 0;
  ul {
    padding: 40px 55px;
    li {
      float: left;
    }
  }
}
.login-tab-switch {
  right: 20px;
  top: 20px;
  left: auto;

  .erweima {
    background-image: url(./img/sign_icon.png);

    background-size: 95px 65px;
    background-position: -70px -5px;
    width: 20px;
    height: 20px;
    float: right;
    margin: -25px -110px;
  }
}
.btn-switch {
  position: absolute;
  z-index: 1005;
  left: 300px;
  top: 18px;
  text-align: center;
  width: 42px;
  height: 42px;
  line-height: 32px;
  background: no-repeat;
  background-size: 42px 84px;
  cursor: pointer;
}
.switch-tip {
  width: 117px;
  height: 30px;
  background: #5dd5c8;
  color: #fff;
  box-shadow: 0 1px 7px 0 rgba(93, 213, 200, 0.2);
  font-size: 13px;
  top: -5px;
  right: 35px;
  left: auto;
}
.switch-tip:before {
  top: 8px;
  right: -84px;
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid #5dd5c8;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  position: absolute;
}
.sign-form {
  margin-left: 10px;
  padding-top: 40px;
  .qiehuan li {
    border: 1px solid rgba(227, 231, 237, 1);
    width: 152px;
    height: 42px;
    line-height: 42px;
    cursor: pointer;
    margin: 0 10px;
  }
  li:hover {
    color: #5dd5c8;
  }
}
.active {
  border: 1px solid #5dd5c8 !important;
  color: #5dd5c8;
}
.form-row .icon-sign-phone {
  background-image: url(./img/icons-sign.png);
  background-repeat: no-repeat;
  -webkit-transition: box-shadow linear 0.2s;
  transition: box-shadow linear 0.2s;
  width: 20px;
  height: 20px;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 82px;
  top: 151px;
  background-position: 2px 1px;
}
.form-row2 .icon-sign-phone {
  background-image: url(./img/icons-sign.png);
  background-repeat: no-repeat;
  -webkit-transition: box-shadow linear 0.2s;
  transition: box-shadow linear 0.2s;
  width: 20px;
  height: 20px;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 82px;
  top: 217px;
  background-position: 2px -90px;
}
.forms {
  margin: 20px 37px;
  .form-row input {
    border-bottom: 1px solid #e3e7ed;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    height: 38px;
    padding-left: 10px;
  }
  .form-row2 input {
    border-bottom: 1px solid #e3e7ed;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    height: 38px;
    padding-right: 85px;
  }
  .form-row,
  .form-row2,
  .duanxin {
    border: 1px solid #e3e7ed;
    width: 326px;
    height: 40px;
    line-height: 40px;
    margin-left: 28px;
    margin-bottom: 25px;
  }
}
.form-row:active {
  box-shadow: 1px 2px 3px #d7f4f1;
  border-color: #59cac2;
}
.form-row:active .icon-sign-phone {
  background-image: url(./img/icons-sign.png);
  background-repeat: no-repeat;
  -webkit-transition: box-shadow linear 0.2s;
  transition: box-shadow linear 0.2s;
  width: 20px;
  height: 20px;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 82px;
  top: 151px;
  background-position: 2px -185px;
}
.form-row2:active {
  box-shadow: 1px 2px 3px #d7f4f1;
  border-color: #59cac2;
}
.form-row2:active .icon-sign-phone {
  background-image: url(./img/icons-sign.png);
  background-repeat: no-repeat;
  -webkit-transition: box-shadow linear 0.2s;
  transition: box-shadow linear 0.2s;
  width: 20px;
  height: 20px;
  position: absolute;
  width: 20px;
  height: 20px;
  background-position: 2px -277px;
}
/deep/.popper__arrow::after {
  display: none;
}
/deep/.el-dropdown {
  margin-left: -40px;
}
/deep/.el-popper[x-placement^="bottom"] .popper__arrow {
  top: 0px !important;
  border-style: none;
  border-bottom-color: #fff !important;
  display: hidden;
}
/deep/.el-popper .popper__arrow {
  border-width: 0px !important;
}
.xiala {
  left: 575px !important;
  border-radius: 0px !important;
  width: 200px;
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.xiala span {
  float: right;
}
.confirm {
  border: 1px solid #e3e7ed;
  width: 326px;
  height: 40px;
  margin-left: 28px;
  margin-bottom: 25px;
  background-image: linear-gradient(180deg, #ffffff 0%, #f3f3f3 100%);
  opacity: 1;
  line-height: 40px;
  display: flex;
  font-size: 15px;
  flex-direction: row;
  .yuan {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #3873ff;
    margin: 15px 20px;
  }
}
.confirm:hover {
  background-image: linear-gradient(0deg, #fff 0, #f3f3f3 100%);
}
.login button {
  width: 328px;
  font-size: 16px;
  height: 42px;
  line-height: 42px;
  border: none;
  cursor: pointer;
  margin-left: -29px;
  background: #5dd5c8;
  color: white;
}
.text-tip {
  display: flex;
  font-size: 14px;
  flex-direction: row;
  li {
    margin: -10px 25px;
    color: #18c3b1;
  }
}
.text-tip li:first-child:hover {
  color: #18c3b1;
}
.text-tip li:first-child {
  text-align: left;
  color: #9fa3b0;
  cursor: pointer;
  margin-top: -15px;
  margin-left: -20px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    margin-left: -10px;
  }
}
.text-tip li:last-child {
  margin-left: 40px;
}
.duanxin i {
  float: left;
  line-height: 40px;
  margin-right: 10px;
}
.duanxin:active {
  border: 1px solid #18c3b1;
}
.duanxin:active .el-icon-message {
  color: #18c3b1 !important;
}
.phonecode {
  border: 0;
  outline: none;
}
.huixian {
  color: gainsboro;
  margin-right: 15px;
}
.yanzhengma {
  cursor: pointer;
  color: #18c3b1;
}
.el-icon-message {
  color: gainsboro !important;
  margin-left: 5px;
}
</style>
<style>
</style>