<template>
	<div>
		<div class="main">
			<div class="main_l">
				<div class="exam_top">
					<van-count-down :time="1 *60 * 60 * 1000" @change="onChange">
						<template #default="timeData">
							<span class="block">{{ timeData.hours }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.minutes }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.seconds }}</span>
						</template>
					</van-count-down>
					<div class="text-gray"><span class="blue">{{index+1}}</span>/{{allList.length}}</div>
				</div>
				<div class="exam_con">
					<div class="title">
						{{List.questionType==2?'【'+'多选】':'【'+'单选】'}}{{index+1}}、{{List.title}}
					</div>
					<div @click.stop="change(item,index)" style="margin: 15px 0;" class="flex align-center" :key="index"
						v-for="(item,index) in options">
						<img v-if="List.questionType==2" class="round"
							:src="List.checkList.includes(index)?require('../../assets/icon/check.png'):require('../../assets/icon/round.png')" />
						<div class="flex" v-else>
							<img v-if="List.check===index" class="round" src="../../assets/icon/check.png" />
							<img v-else class="round" src="../../assets/icon/round.png" />
						</div>

						{{String.fromCharCode(65+index)}}、
						<img @click.stop="preview(item.value)" v-if="List.optionType==2"
							style="width: 100px;height: 100px;margin-left: 40rpx;" :src="item.value" />
						<span v-else class="">{{item.value}}</span>
					</div>
					<div class="footer_btn">
						<div class="again hand" @click="againBtn()">
							<img src="../../assets/icon/Group 4141@2x.png" alt="" />
							重做
						</div>
						<div class="again hand" @click="backBtn()">
							<img src="../../assets/icon/Group 4142@2x.png" alt="" />
							上一题
						</div>
						<div class="next hand" @click="nextBtn()">
							下一题
							<img src="../../assets/icon/Frame@2x.png" alt="" />
						</div>
					</div>
				</div>

			</div>
			<div class="main_r">
				<div class="main_r_top">
					<div class="flex align-center">
						<img src="../../assets/icon/Group 3796@2x.png" alt="" />
						<span class="lv">{{correct}}</span>
						<img src="../../assets/icon/Group 3797@2x.png" alt="" />
						<span class="red">{{correct==0?0:finishNum-correct}}</span>
					</div>
					<span class="text-gray">{{finishNum}}/{{allList.length}}</span>
				</div>
				<div style="overflow:auto" class="flex box flex-wrap text-center align-center">
					<div :key="index" v-for="(item,index) in allList">
						<div v-if="item.questionType==2&&item.checkList"
							:class="item.checkList.length==0||!item.isfinish?'pop_gray pop_item':item.checkList.sort().toString()===item.answerIndex.toString()?'pop_right pop_item':'pop_error pop_item'">

							<div class="">
								{{index+1}}
							</div>

						</div>
						<div v-else
							:class="!item.isfinish?'pop_gray pop_item':item.check+1==item.answer.toLocaleLowerCase().charCodeAt(0) - 96?'pop_right pop_item':'pop_error pop_item'">

							<span class="">
								{{index+1}}
							</span>

						</div>
					</div>

				</div>
				<div class="submit" @click="submit()">
					<div class="push">
						提交答卷
					</div>
				</div>
			</div>
		</div>
		<el-dialog top="22vh" width="16%" class="end_pop" :show-close="false" :visible.sync="endShow">
			<div class="over_con">
				<img class="over_bg" src="../../assets/icon/reward.png" />
				<div class="over_son">
					<div style="font-size: 18px;">
						得分
					</div>
					<div class="score text-bold">
						{{correct}}
					</div>
					<div class="margin-top-sm">
						{{correct>80?'考试通过':'成绩不合格'}}
					</div>
				</div>
			</div>
			<div class="flex align-center justify-center">
				<div class="exit" @click="exit()">
					退出
				</div>
				<div class="reset" @click="againBtn()">
					重新答题
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		HttpRequest
	} from '@/utils/http';

	import {
		mapState
	} from "vuex";
	import {
		Message
	} from "element-ui";
	export default {
		name: 'moni',
		data() {
			return {
				examType: this.$route.query.type, //0模拟1正式
				endShow: false,
				allList: [], //所有题目
				checkList: [],
				correct: 0, //正确数量
				correctList: [],
				index: 0, //当前题目索引
				List: {},
				timeData: {},
				options: [],
				checkId: 500,
				finishNum: 0, //完成的题目数量
				errorSudIds: '', //错误题目集合
				id: this.$route.query.id,
				title: this.$route.query.title,
			}

		},

		mounted() {
			console.log(this.examType)
			this.getInfo()

		},
		methods: {
			submit() {
				var num = []
				this.allList.map(val => {
					if (val.isfinish) {
						num.push(val)
					}
				})
				this.finishNum = num.length

				var error = []
				this.allList.map(val => {
					if (!val.isAnswer) {
						error.push(val.id)
					}
				})
				let times = new Date()
				var data = {
					endTime: this.timeWeekFormat(times),
					title: this.title,
					typeId: this.id,
					achievement: this.correct,
					correct: this.correct,
					error: Number(this.allList.length - this.correct),
					activeId: this.examType == 0 ? 0 : -1,
					errorSudIds: error.join(',')
				}
				console.log(data)
				HttpRequest.getRequest({
					method: "POST",
					data: data,
					url: "/app/api/examination/submitExamRecords"
				}).then(res => {
					console.log(res.data.data)
					if (res.data.code == 200) {
						this.endShow = true
					}
				})

			},
			timeWeekFormat(times) {
				//定义一个日期对象;
				var dateTime = new Date(times);
				//获得系统年份;
				var year = dateTime.getFullYear();
				//获得系统月份;
				var month = dateTime.getMonth() + 1;
				//获得系统当月分天数;
				var day = dateTime.getDate();
				//获得系统小时;
				var hours = dateTime.getHours();
				//获得系统分钟;
				var minutes = dateTime.getMinutes();
				//获得系统秒数;
				var second = dateTime.getSeconds();
				//获得系统星期几;
				var dayCycle = dateTime.getDay();
				//使用数组更改日期样式;
				var dayCycleArray = ["日", "一", "二", "三", "四", "五", "六"];
				for (var i = 0; i < 7; i++) {
					if (dayCycle == i) {
						//将dayCycleArray的数赋值到系统星期几里面中去;
						dayCycle = dayCycleArray[i];
					}
				}
				month < 10 ? month = '0' + month : month;
				day < 10 ? day = '0' + day : day;
				hours < 10 ? hours = '0' + hours : hours;
				minutes < 10 ? minutes = '0' + minutes : minutes;
				second < 10 ? second = '0' + second : second;
				//打印完整的系统日期;
				var time = hours + ':' + minutes + ':' + second
				var dateStr = year + '-' + month + '-' + day + ' ' + time
				this.week = '星期' + dayCycle

				return dateStr;
			},
			nextBtn() {
				console.log(this.List.check, this.List.questionType)
				if (this.List.check && this.List.questionType != 2 || this.List.check == 0) {
					this.List.isfinish = true
				}
				if (this.List.questionType == 2 && this.List.checkList.length != 0) {
					this.List.isfinish = true
				}
				if (this.index == this.allList.length - 1) {
					Message.error('已经是最后一题啦~')

					return
				}
				this.index += 1
				this.checkId = 200
				this.List = this.allList[this.index]
				if (this.List.questionType == 2) {
					this.List['checkList'] = []
					var a = this.List.answer.split(',')
					this.List['answerIndex'] = []
					a.map(val => {
						this.List['answerIndex'].push(val.toUpperCase().charCodeAt() - 64)
					})
				}
				this.options = JSON.parse(this.List.options)
				console.log(this.allList)
				var num = []
				this.allList.map(val => {
					if (val.isfinish) {
						num.push(val)
					}
				})
				console.log(num.length - this.correct)
				this.finishNum = num.length

			},
			backBtn() {
				if (this.index == 0) {
					Message.error('已经是第一题啦~')
					return
				}
				if (this.List.check && this.List.questionType != 2) {
					this.List.isfinish = true
				}
				if (this.List.questionType == 2 && this.List.checkList.length != 0) {
					this.List.isfinish = true
				}
				if (this.index != 0) {
					this.index -= 1
					this.List = this.allList[this.index]
					this.checkId = this.List.check
					console.log(this.index)
					this.options = JSON.parse(this.List.options)
				}
			},
			getInfo() {
				HttpRequest.getRequest({
					method: "POST",
					url: '/app/api/examination/getMockExam?typeId=' + this.id,
				}).then(res => {
					console.log(res.data.data)
					if (res.data.data) {
						this.allList = res.data.data
						this.List = res.data.data[this.index]

						console.log(this.List.answer.toLocaleLowerCase().charCodeAt(0) - 96);
						this.options = JSON.parse(this.List.options)
						if (this.List.questionType == 2) {
							this.List['checkList'] = []
							var a = this.List.answer.split(',')
							this.List['answerIndex'] = []
							a.map(val => {
								this.List['answerIndex'].push(val.toUpperCase().charCodeAt() - 64)
							})

						} else {
							this.List['check'] = ''
						}
						console.log(this.List)
					}

				});
			},
			onChange(e) {
				this.timeData = e
			},
			change(item, index) {
				if (this.List.questionType == 2) {
					if (!this.List.checkList.includes(index)) {

						this.List.checkList.push(index)
					} else {
						this.List.checkList.splice(this.List.checkList.findIndex(item => item === index), 1);
					}
					// this.$set(this.List, 'checkList', this.List.checkList)
					console.log(this.List)
					// this.List = Object.assign({}, this.List.checkList, this.List.checkList)
				} else {
					this.List.check = index
				}

				if (this.index == this.allList.length - 1) {
					this.List.isfinish = true
				}
				// console.log(this.List)
				this.$forceUpdate();
				this.correctList = []
				this.errorSudIds = []
				daobuliao
				this.allList.map(val => {
					if (val.check === val.answer.toLocaleLowerCase().charCodeAt(0) -
						97) {
						console.log(val.check, val.answer.toLocaleLowerCase().charCodeAt(0) - 97)
						this.correctList.push(val)
						val.isAnswer = true
					} else {
						this.errorSudIds.push(val.id)
					}
					if (val.checkList) {
						if (val.checkList.sort().toString() === val.answerIndex.toString()) {
							this.correctList.push(val)
							val.isAnswer = true
						} else {
							this.errorSudIds.push(val.id)
						}
					} else {
						this.errorSudIds.push(val.id)
					}
				})

				this.correct = this.correctList.length
				var num = []
				this.allList.map(val => {
					if (val.isfinish) {
						num.push(val)
					}
				})
				console.log(num)
				this.finishNum = num.length
			},
			exit() {
				this.endShow = false
			},
			againBtn() {
				this.correct = 0
				this.finishNum = 0
				this.index = 0
				this.getInfo()
				this.endShow = false
			},
		}
	}
</script>

<style scoped lang="less">
	::v-deep .el-dialog__header {
		padding: 0;
	}

	::v-deep .el-dialog {
		border-radius: 4px;
	}

	.over_con {
		position: relative;
		padding: 5px 35px;

		.over_bg {
			width: 175px;
		}

		.over_son {
			text-align: center;
			position: absolute;
			width: 100%;
			left: 0;
			right: 0;
			top: 30px;
			color: #fff;
		}

		.score {
			font-size: 38px;
			margin: 5px 0;
		}
	}

	.exit {
		margin-right: 12px;
		width: 40%;
		height: 41px;
		line-height: 41px;
		text-align: center;
		border-radius: 5px;
		color: #227BF9;
		border: 1px solid #227BF9;
		margin-bottom: 10px;
		cursor: pointer;
	}

	.reset {
		margin-right: 12px;
		width: 40%;
		height: 41px;
		line-height: 41px;
		text-align: center;
		border-radius: 5px;
		color: #fff;
		background-color: #227BF9;
		margin-bottom: 10px;
		cursor: pointer;
	}

	.main {
		display: flex;
		align-items: center;
		width: 70%;
		margin: 20px auto;
		justify-content: space-between;
	}

	.main_l {
		background-color: #fff;
		border-radius: 10px;
		width: 62%;
		min-height: 700px;
	}

	.main_r {
		position: relative;
		background-color: #fff;
		border-radius: 10px;
		width: 35%;
		min-height: 700px;
	}

	.exam_top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		background-color: #EDF4FF;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	.colon {
		display: inline-block;
		margin: 0 4px;
		color: #227BF9;
	}

	.block {
		display: inline-block;
		width: 22px;
		color: #fff;
		border-radius: 4px;
		font-size: 12px;
		text-align: center;
		background-color: #227BF9;
	}

	.exam_con {
		padding: 0 20px;
	}

	.title {
		margin: 20px 0;
		text-align: left;
	}

	.round {
		margin-right: 10px;
		width: 20px;
	}

	.footer_btn {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.footer_btn img {
		width: 20px;
		margin: 0 5px;
	}

	.again {
		width: 115px;
		height: 42px;
		border-radius: 4px;
		display: flex;
		margin: 0 14px;
		color: #227BF9;
		align-items: center;
		justify-content: center;
		border: 1px solid #227BF9;
	}

	.next {
		background-color: #227BF9;
		color: #fff;
		width: 115px;
		height: 42px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.main_r_top {
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.main_r_top img {
		width: 13px;
		margin: 0 5px;
	}

	.lv {
		color: #57E1C5;
	}

	.red {
		color: #F06060;
	}

	.pop_item {
		// height: 80rpx;
		width: 45px;
		height: 45px;
		line-height: 45px;
		text-align: center;
		// line-height: 80rpx;
		margin: 15px 12px 0 0;
	}

	.box .pop_item:nth-child(6n) {
		margin: 15px 0 0 0;
	}

	.box {
		padding: 0 15px;
	}

	.pop_error {
		border-radius: 50%;
		color: #F06060;
		border: 1px solid #F06060;
	}

	.pop_gray {
		border-radius: 50%;
		color: #777;
		border: 1px solid #D9D9D9;
	}

	.pop_right {
		border-radius: 50%;
		color: #57E1C5;
		border: 1px solid #57E1C5;

	}

	.submit {
		position: absolute;
		z-index: 99;
		bottom: 0;
		width: 100%;
		left: 0;
		right: 0;
		box-shadow: 1px -1px 17px rgba(0, 0, 0, 0.4);
	}

	.push {
		background-color: #227BF9;
		color: #fff;
		width: 95%;
		margin: 10px auto;
		height: 50px;
		border-radius: 4px;
		text-align: center;
		line-height: 50px;
	}
</style>