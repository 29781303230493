<template>
  <div>
    <div class="top">
      <ul class="top_l">
        <li><h2 class="boss">BOSS直聘</h2></li>
      <router-link to="./Start">  <li>首页</li></router-link>
        <li @click="zhiwei">职位</li>
        <li>
          <el-popover
            ref="popover1"
            placement="bottom"
            title=""
            width="400"
            height="290"
            trigger="hover"
          >
            <img
              src="./img/guanggao1.png"
              alt=""
              style="width: 400px; height: 100px"
            />
            <img
              src="./img/guanggao3.png"
              alt=""
              style="width: 400px; height: 100px"
            />
            <img
              src="./img/guanggao2.png"
              alt=""
              style="width: 400px; height: 100px"
            />
          </el-popover>
          <el-button v-popover:popover1
            >校园<i class="el-icon-caret-bottom"></i
          ></el-button>
        </li>
        <li>公司</li>
        <li>咨讯</li>
      </ul>
      <ul>
        <li>消息</li>
        <li>简历·上传</li>
        <li class="tel">
          <el-dropdown trigger="hover" :hide-on-click="false">
            <span class="el-dropdown-link"> 林静 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click="person">
                <p @click="person">个人中心</p>
                <p class="xiahuaxian">推荐职位、编辑在线简历</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p>账号设置</p>
                <p class="xiahuaxian">修改密码、打招呼语和常用语</p>
              </el-dropdown-item>
              <el-dropdown-item
                ><p style="border-bottom: 1px solid gainsboro">
                  隐私设置
                </p></el-dropdown-item
              >
              <el-dropdown-item>求职助手</el-dropdown-item>
              <el-dropdown-item class="baikuai">切换为招聘者</el-dropdown-item>
              <el-dropdown-item class="baikuai">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="container">
        <div class="user-sider">
          <div>
            <div class="sider-box userinfo-box">
              <a href="" class="edit-userinfo">编辑</a>
              <div class="headbox">
                <div class="sex"></div>
                <img src="./img/touxiang.jpg" alt="" class="headimg" />
              </div>
              <div class="username">
                林静
                <i class="icon-vip"></i>
              </div>
              <div class="userinfo">
                <p>
                  <span> 22岁</span><span>|</span><span>一年</span><span>|</span
                  ><span>大专</span>
                </p>
              </div>
              <div class="now-state">
                <p class="ui-select-selection" @click="show()">
                  {{ defcity }}
                  <i class="el-icon-arrow-down"></i>
                </p>
                <div id="ui-select-dropdown" style="display: none">
                  <ul>
                    <li
                      v-for="(v, index) in nowstate"
                      :key="index"
                      @click="qh(v)"
                    >
                      {{ v }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="user-stat">
                <a href="">
                  <p>39</p>
                  沟通过
                </a>
                <a href="">
                  <p>1</p>
                  已投递
                </a>
                <a href="">
                  <p>0</p>
                  面试
                </a>
                <a href="">
                  <p>0</p>
                  感兴趣
                </a>
              </div>
            </div>
            <div class="sider-box sider-resume">
              <div class="resume-attachment">
                <p class="sider-title">附件管理</p>
                <button class="btn btn-primary">上传简历</button>
              </div>
              <div class="resume-online">
                <p>在线简历 <span class="edit">编辑</span></p>
                <p class="upt-time">2020.11.02 更新</p>
              </div>
            </div>
            <div class="sider-box sider-recommend">
              <a href="">
                <p class="yi">
                  对我感兴趣
                  <i class="el-icon-arrow-right"></i>
                </p>
              </a>
              <a href="">
                <p>
                  对我感兴趣
                  <i class="el-icon-arrow-right"></i>
                </p>
              </a>
            </div>
            <div class="sider-box sider-vip">
              <p class="niuren">牛人VIP</p>
              <ul>
                <li>
                  <i class="vip-icon vip-icon-resume"></i>
                  简历刷新Pro
                </li>
                <li>
                  <i class="vip-icon vip-icon-data"></i>
                  竞争力分析
                </li>
                <li>
                  <i class="vip-icon vip-icon-vip"></i>
                  尊贵身份标识
                </li>
                <li>
                  <i class="vip-icon vip-icon-diamonds"></i>
                  VIP专属报告
                </li>
              </ul>
              <button class="btn btn-primary">升级VIP</button>
            </div>
            <div class="sider-box refresh-lines">
              <div class="huoyue">
                <h4>简历活跃度</h4>
                <div class="svg-sub">
                  <p><span class="gdot"></span>我的</p>
                  <p><span class="ydot"></span>我的</p>
                </div>
                <button class="btn btn-primary">提升活跃度</button>
                <p class="refresh-text">
                  剩余免费次数<span class="refresh-free-count">2次</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="user-jobs-area">
          <div class="filter-box">
            <div class="sousuo">
              <template>
                <el-button type="text" @click="dialogTableVisible = true"
                  >{{ cityGroup.locationCity.name
                  }}<i class="el-icon-caret-bottom"></i
                  ><span class="huixian"></span
                ></el-button>
                <el-dialog
                  title="请选择城市"
                  :visible.sync="dialogTableVisible"
                >
                  <el-tabs
                    v-model="activeName"
                    @tab-click="handleClick"
                    class="el"
                  >
                    <el-tab-pane label="热门城市" name="second" class="el_zong">
                      <ul class="section-citys">
                        <li
                          v-for="i in cityGroup.hotCityList"
                          :key="i.id"
                          @click="qhcsm(i.name)"
                        >
                          {{ i.name }}
                        </li>
                      </ul>
                    </el-tab-pane>
                    <el-tab-pane label="ABCDE" name="first" class="el_zong">
                      <div class="ae">
                        <template v-for="(i, index) in cityGroup.cityGroup">
                          <div class="aeitem" v-if="index < 5" :key="index">
                            <div class="aeiteml">{{ i.firstChar }}</div>
                            <div class="aeitemr">
                              <span
                                v-for="l in i.cityList"
                                :key="l.id"
                                class="aeitemss"
                                @click="qhcsm(l.name)"
                                >{{ l.name }}</span
                              >
                            </div>
                          </div>
                        </template>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="FGHJ" name="third" class="el_zong">
                      <div class="ae">
                        <template v-for="(i, index) in cityGroup.cityGroup">
                          <div
                            class="aeitem"
                            :key="index"
                            v-if="index > 4 && index < 9"
                          >
                            <div class="aeiteml">{{ i.firstChar }}</div>
                            <div class="aeitemr">
                              <span
                                v-for="l in i.cityList"
                                :key="l.id"
                                class="aeitemss"
                                @click="qhcsm(l.name)"
                                >{{ l.name }}</span
                              >
                            </div>
                          </div>
                        </template>
                      </div></el-tab-pane
                    >
                    <el-tab-pane label="KLMN" name="fourth" class="el_zong">
                      <div class="ae">
                        <template v-for="(i, index) in cityGroup.cityGroup">
                          <div
                            class="aeitem"
                            :key="index"
                            v-if="index > 8 && index < 13"
                          >
                            <div class="aeiteml">{{ i.firstChar }}</div>
                            <div class="aeitemr">
                              <span
                                v-for="l in i.cityList"
                                :key="l.id"
                                class="aeitemss"
                                @click="qhcsm(l.name)"
                                >{{ l.name }}</span
                              >
                            </div>
                          </div>
                        </template>
                      </div></el-tab-pane
                    >
                    <el-tab-pane label="PQRST" name="fifth" class="el_zong"
                      ><div class="ae">
                        <template v-for="(i, index) in cityGroup.cityGroup">
                          <div
                            class="aeitem"
                            :key="index"
                            v-if="index > 12 && index < 18"
                          >
                            <div class="aeiteml">{{ i.firstChar }}</div>
                            <div class="aeitemr">
                              <span
                                v-for="l in i.cityList"
                                :key="l.id"
                                class="aeitemss"
                                @click="qhcsm(l.name)"
                                >{{ l.name }}</span
                              >
                            </div>
                          </div>
                        </template>
                      </div></el-tab-pane
                    >
                    <el-tab-pane label="WXYZ" name="sixth" class="el_zong">
                      <div class="ae">
                        <template v-for="(i, index) in cityGroup.cityGroup">
                          <div
                            class="aeitem"
                            :key="index"
                            v-if="index > 17 && index < 22"
                          >
                            <div class="aeiteml">{{ i.firstChar }}</div>
                            <div class="aeitemr">
                              <span
                                v-for="l in i.cityList"
                                :key="l.id"
                                class="aeitemss"
                                @click="qhcsm(l.name)"
                                >{{ l.name }}</span
                              >
                            </div>
                          </div>
                        </template>
                      </div></el-tab-pane
                    >
                  </el-tabs>
                </el-dialog>
              </template>
              <input placeholder="搜索职位、公司" v-model="input3" />
              <span class="huixian"></span>
              <el-cascader
                expand-trigger="hover"
                :options="options"
                :show-all-levels="false"
                class="xiala"
                v-model="selectedOptions3"
                placeholder="职位类型"
              ></el-cascader>
              <span class="huixian"></span>
              <el-popover
                ref="popover2"
                placement="bottom"
                title="不限"
                width="700"
                height="290"
                trigger="click"
                class="popover"
              >
                <ul class="duihuak">
                  <li v-for="(v, code) in datas" :key="code">{{ v.name }}</li>
                </ul>
              </el-popover>
              <el-button v-popover:popover2
                >公司行业<i class="el-icon-caret-bottom"></i
              ></el-button>
              <i class="el-icon-search"></i>
            </div>
            <div class="city-wrapper">
              <p>上海<i class="el-icon-arrow-right"></i></p>
              <p class="selected">不限</p>
            </div>
            <div class="city-wrapper">
              <p>不限</p>
              <ul v-for="(h, index) in area" :key="index" class="hotcity">
                <li>{{ h }}</li>
              </ul>
            </div>
            <div :class="{ dixiaall: true, actives: pagey > 190 ? true : false }">
        <div class="dixia">
          <div class="zuob">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-submenu index="2">
                <template slot="title">{{ gzjy }}</template>
                <el-menu-item
                  v-for="(i, index) in screen.workingYears"
                  :key="index"
                  @click="gzjyqh(i)"
                  >{{ i }}</el-menu-item
                >
              </el-submenu>
              <i class="el-icon-caret-bottom"></i>
            </el-menu>
          </div>
          <div class="zuob">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-submenu index="2">
                <template slot="title">{{ xlyq }}</template>
                <el-menu-item
                  v-for="(i, index) in screen.education"
                  :key="index"
                  @click="xlyqqh(i)"
                  >{{ i }}</el-menu-item
                >
              </el-submenu>
              <i class="el-icon-caret-bottom"></i>
            </el-menu>
          </div>
          <div class="zuob">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-submenu index="2">
                <template slot="title">{{ xzyq }}</template>
                <el-menu-item
                  v-for="(i, index) in screen.salary"
                  :key="index"
                  @click="xzyqqh(i)"
                  >{{ i }}</el-menu-item
                >
              </el-submenu>
              <i class="el-icon-caret-bottom"></i>
            </el-menu>
          </div>
          <div class="zuob">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-submenu index="2">
                <template slot="title">{{ rzjd }}</template>
                <el-menu-item
                  v-for="(i, index) in screen.financing"
                  :key="index"
                  @click="rzjdqh(i)"
                  >{{ i }}</el-menu-item
                >
              </el-submenu>
              <i class="el-icon-caret-bottom"></i>
            </el-menu>
          </div>
          <div class="zuob">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-submenu index="2">
                <template slot="title">{{ gsgm }}</template>
                <el-menu-item
                  v-for="(i, index) in screen.scale"
                  :key="index"
                  @click="gsgmqh(i)"
                  >{{ i }}</el-menu-item
                >
              </el-submenu>
              <i class="el-icon-caret-bottom"></i>
            </el-menu>
          </div>
          <div class="zuob">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-submenu index="2">
                <template slot="title">{{ pubtime }}</template>
                <el-menu-item
                  v-for="(i, index) in screen.releaseTime"
                  :key="index"
                  @click="pubtimeqh(i)"
                  >{{ i }}</el-menu-item
                >
              </el-submenu>
              <i class="el-icon-caret-bottom"></i>
            </el-menu>
          </div>
          <!-- <div class="zongtow1" @click="qk">
            <div>清空筛选条件</div>
          </div> -->
        </div>
      </div>
            <div></div>
          </div>
          <div>
            <ul class="qiehuan">
              <li
                v-for="(v, index) in tab"
                :key="index"
                @click="ifshow = index"
                :class="{ active: ifshow == index }"
              >
                {{ v }}
              </li>
            </ul>
            <div v-show="ifshow == 0">
              <div class="job-box">
                <div class="job-left">
                  <div class="job-list" v-for="(v, index) in job" :key="index">
                    <div class="job-primary">
                      <div class="job-primary-top">
                        <div class="info-primary">
                          <p style="color: #5fd5c9; text-align: left">
                            {{ v.jobname }}[{{ v.jobarea }}]<span></span>
                          </p>
                          <p style="text-align: left">
                            <span class="red-money">{{ v.red }}</span
                            ><span>{{ v.date }}</span
                            ><span>|</span><span>{{ v.edu }}</span>
                            <span class="info-publis"
                              ><img :src="v.chat" alt="" /><span>{{
                                v.person
                              }}</span
                              >|<span>{{ v.position }}</span></span
                            >
                          </p>
                        </div>
                        <div class="primary-right">
                          <p style="color: #5fd5c9">
                            {{ v.title }}
                          </p>
                          <p style="font-size: 13px; color: #61687c">
                            {{ v.position }}<span>|</span
                            ><span>{{ v.rongzi }}</span
                            ><span>{{ v.guimo }}</span>
                          </p>
                        </div>
                        <div>
                          <img :src="v.img" alt="" style="float: right" />
                        </div>
                      </div>
                      <div class="job-primary-bottom">
                        <div v-for="(c, ind) in v.children" :key="ind">
                          <p class="kuang">{{ c }}</p>
                        </div>
                        <div class="list">
                          <div v-for="(d, indd) in v.list" :key="indd">
                            <p>{{ d }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="ifshow == 1">
              <div class="job-box">
                <div class="job-left">
                  <div class="job-list" v-for="(v, index) in job2" :key="index">
                    <div class="job-primary">
                      <div class="job-primary-top">
                        <div class="info-primary">
                          <p style="color: #5fd5c9; text-align: left">
                            {{ v.jobname }}[{{ v.jobarea }}]<span></span>
                          </p>
                          <p style="text-align: left">
                            <span class="red-money">{{ v.red }}</span
                            ><span>{{ v.date }}</span
                            ><span>|</span><span>{{ v.edu }}</span>
                            <span class="info-publis"
                              ><img :src="v.chat" alt="" /><span>{{
                                v.person
                              }}</span
                              >|<span>{{ v.position }}</span></span
                            >
                          </p>
                        </div>
                        <div class="primary-right">
                          <p style="color: #5fd5c9">
                            {{ v.title }}
                          </p>
                          <p style="font-size: 13px; color: #61687c">
                            {{ v.position }}<span>|</span
                            ><span>{{ v.rongzi }}</span
                            ><span>{{ v.guimo }}</span>
                          </p>
                        </div>
                        <div>
                          <img :src="v.img" alt="" style="float: right" />
                        </div>
                      </div>
                      <div class="job-primary-bottom">
                        <div v-for="(c, ind) in v.children" :key="ind">
                          <p class="kuang">{{ c }}</p>
                        </div>
                        <div class="list">
                          <div v-for="(d, indd) in v.list" :key="indd">
                            <p>{{ d }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="fenye">
            <el-pagination background layout="prev, pager, next" :total="30">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="last">
      <div class="last_l">
        <div class="last_ll">
          <ul class="last_ula">
            <li>企业服务</li>
            <li>职位搜索</li>
            <li>新闻资讯</li>
            <li>BOSS直聘APP</li>
          </ul>
          <ul class="last_ulb">
            <li>使用与帮助</li>
            <li>平台协议</li>
            <li>防骗指南</li>
            <li>使用帮助</li>
          </ul>
          <ul>
            <li>联系BOSS直聘</li>
            <li>北京华品博睿网络技术有限公司</li>
            <li>公司地址 北京市朝阳区太阳宫中路8号冠捷大厦302</li>
            <li>联系电话 010-84150633</li>
            <li>违法和不良信息举报邮箱 jubao@kanzhun.com</li>
          </ul>
        </div>
        <div class="last_r">
          <h2>BOSS直聘</h2>
          <p>企业服务热线和举报投诉 <span>400 065 5799</span></p>
          <p>工作日 <span>8:00 - 22:00</span></p>
          <p>休息日 <span>9:30 - 18:30</span></p>
        </div>
      </div>
      <div class="last_b">
        <p>
          <span>Copyright © 2020 BOSS直聘</span><span>京ICP备14013441号-5</span
          ><span>京ICP证150923号</span><span>京网文[2020]0399-066 号</span>
          <span class="jingguo"
            ><img
              src="./img/zhizhao.png"
              alt=""
              style="width: 15px; height: 15px"
            />
            电子营业执照</span
          >
          <span class="jingguo"
            ><img
              src="./img/gongan.png"
              alt=""
              style="width: 15px; height: 15px"
            />
            京公网安备11010502032801</span
          >
          <span class="icon-weibo"><img src="./img/app.png" alt="" /> </span>
          <span class="icon-weibo"><img src="./img/weixin.png" alt="" /> </span>
          <span class="icon-weibo"><img src="./img/weibo.png" alt="" /> </span>
        </p>
        <p class="last_b_lv">
          <span>首都网警</span><span>人力资源服务许可证</span
          ><span>网上有害信息举报专区</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datas: [],
      cityGroup: [],
      screen:[],
      ifshow: 0,
      pagey:'',
      activeIndex:'1',
       dgshy: "公司行业",
      gzjy: "工作经验",
      xlyq: "学历要求",
      xzyq: "薪资要求",
      rzjd: "融资阶段",
      gsgm: "公司规模",
      pubtime: "发布时间",
      defcity: "在职-暂不考虑",
      tab: ["推荐职位", "最新职位"],
      dialogTableVisible: false,
      activeName: "first",
      input3: "",
      selectedOptions3: "",
      nowstate: [],
      guimo: [
        "不限",
        "0-20人",
        "20-99人",
        "100-499人",
        "500-999人",
        "1000-9999人",
        "10000人及以上",
      ],
      job2: [
        {
          jobname: "人民广场招聘网站前端",
          jobarea: "临沂·兰山区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "不限",
          person: "王先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop1.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),

          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: [
            "五险一金，",
            "年终奖，",
            "餐补，",
            "带薪年假，",
            "通信补贴，",
            "加班补助，",
            "定期体检，",
            "交通补助，",
            "免费班车，",
            "员工旅游，",
            "节日福利，",
            "零食下午补充医疗保险茶",
            "",
          ],
        },
        {
          jobname: "Web前端工程师",
          jobarea: "上海·长宁区·新华路",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop1.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "大专",
          person: "王立国",
          position: "流程运营",
          title: "饿了么",
          type: "移动互联网",
          rongzi: "D轮及以上",
          guimo: "1000-9999人",
          img: require("./img/zhiweitop3.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金，", "股票期权，", "餐补，", "女生，", "定期体检"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop1.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop4.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop2.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "5-10年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop3.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop4.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop4.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
      ],
      job: [
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop1.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),

          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: [
            "五险一金，",
            "年终奖，",
            "餐补，",
            "带薪年假，",
            "通信补贴，",
            "加班补助，",
            "定期体检，",
            "交通补助，",
            "免费班车，",
            "员工旅游，",
            "节日福利，",
            "零食下午补充医疗保险茶",
            "",
          ],
        },
        {
          jobname: "供水系统解决方案运营",
          jobarea: "上海·长宁区·新华路",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop1.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "大专",
          person: "王立国",
          position: "流程运营",
          title: "饿了么",
          type: "移动互联网",
          rongzi: "D轮及以上",
          guimo: "1000-9999人",
          img: require("./img/zhiweitop3.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金，", "股票期权，", "餐补，", "女生，", "定期体检"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop1.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop4.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop2.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "5-10年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop3.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop4.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
        {
          jobname: "餐饮系统解决方案运营",
          jobarea: "上海·普陀区·长征",
          red: "30-50K·15薪",
          date: "3-5年",
          edu: "本科",
          person: "方先生",
          position: "高级专家",
          title: "饿了么",
          type: "O2O",
          rongzi: "不需要融资",
          guimo: "1000人以上",
          img: require("./img/zhiweitop4.jpg"),
          img2: require("./img/icon-chat-hover-v2.png"),
          chat: require("./img/icon-chat.png"),
          children: ["架构师", "技术原理", "网络架构师", "数据通信", "如tcp"],
          list: ["五险一金"],
        },
      ],
      options: [
        {
          value: "zhinan",
          label: "不限",
          children: [],
        },
        {
          value: "zujian",
          label: "高级管理",
          children: [
            {
              value: "basic",
              label: "高级管理职位",
              children: [
                {
                  value: "layout",
                  label: "高级管理职位",
                },
                {
                  value: "color",
                  label: "总裁/总经理/CEO",
                },
                {
                  value: "typography",
                  label: "副总裁/副总经理/VP",
                },
                {
                  value: "icon",
                  label: "分公司/代表处负责人",
                },
              ],
            },
          ],
        },
        {
          value: "ziyuan",
          label: "技术",
          children: [
            {
              value: "axure",
              label: "后端开发",
              children: [
                {
                  value: "quanzhan",
                  label: "全栈工程师",
                },
                {
                  value: "gis",
                  label: "GIS工程师",
                },
                {
                  value: "hd",
                  label: "后端开发",
                },
                {
                  value: "java",
                  label: "Java",
                },
                {
                  value: "cc",
                  label: "C++",
                },
                {
                  value: "php",
                  label: "PHP",
                },
                {
                  value: "c",
                  label: "C",
                },
                {
                  value: "ccc",
                  label: "C#",
                },
                {
                  value: "net",
                  label: ".NET",
                },
                {
                  value: "hedoop",
                  label: "Hedoop",
                },
                {
                  value: "python",
                  label: "Python",
                },
                {
                  value: "delphi",
                  label: "Delphi",
                },
                {
                  value: "vb",
                  label: "VB",
                },
                {
                  value: "perl",
                  label: "Perl",
                },
                {
                  value: "ruby",
                  label: "Ruby",
                },
                {
                  value: "node",
                  label: "Node.js",
                },
                {
                  value: "golang",
                  label: "Golang",
                },
                {
                  value: "erlang",
                  label: "Erlang",
                },
                {
                  value: "yuyin",
                  label: "语音/视频/图形开发",
                },
                {
                  value: "shujucaiji",
                  label: "数据采集",
                },
              ],
            },
            {
              value: "sketch",
              label: "移动开发",
              children: [
                {
                  value: "ue4",
                  label: "UE4",
                },
                {
                  value: "yindongkf",
                  label: "移动开发",
                },
                {
                  value: "html5",
                  label: "HTML5",
                },
                {
                  value: "Android",
                  label: "Android",
                },
                {
                  value: "u3d",
                  label: "U3D",
                },
                {
                  value: "cocos2dx",
                  label: "COCOS2DX",
                },
                {
                  value: "flash",
                  label: "Flash开发",
                },
              ],
            },
            {
              value: "jiaohu",
              label: "测试",
              children: [
                {
                  value: "shentou",
                  label: "渗透测试",
                },
                {
                  value: "shentou",
                  label: "测试工程师",
                },
              ],
            },
            {
              value: "yunwei",
              label: "运维/技术支持",
            },
            {
              value: "shuju",
              label: "数据",
            },
            {
              value: "xiangmu",
              label: "项目管理",
            },
            {
              value: "yingjian",
              label: "硬件开发",
            },
            {
              value: "qianduan",
              label: "前端开发",
            },
            {
              value: "tongxin",
              label: "通信",
            },
            {
              value: "daoti",
              label: "电子/半导体",
            },
            {
              value: "gd",
              label: "高端技术职位",
            },
            {
              value: "rengong",
              label: "人工智能",
            },
            {
              value: "xiaoshou",
              label: "销售技术支持",
            },
            {
              value: "qita",
              label: "其他技术职位",
            },
          ],
        },
      ],
      hotcity: [
        "全国",
        "北京",
        "上海",
        "广州",
        "深圳",
        "杭州",
        "天津",
        "西安",
        "苏州",
        "武汉",
        "厦门",
        "长沙",
        "成都",
        "郑州",
        "重庆",
      ],
      area: [
        "浦东新区",
        "闵行区",
        "徐汇区",
        "静安区",
        "松江区",
        "普陀区",
        "嘉定区",
        "杨浦区",
        "长宁区",
        "宝山区",
        "黄浦区",
        "虹口区",
        "青浦区",
        "奉贤区",
        "金山区",
        "崇明区",
      ],
      jingyan: [
        "不限",
        "在校生",
        "应届生",
        "1年以内",
        "1-3年",
        "3-5年",
        "5-10年",
        "10年以上",
      ],
      xueli: [
        "不限",
        "初中及以下",
        "中专/中技",
        "高中",
        "大专",
        "本科",
        "硕士",
        "博士",
      ],
      salary: [
        "不限",
        "3K以下",
        "3-5K",
        "5-10K",
        "10-15K",
        "15-20K",
        "20-30K",
        "30-50K",
        "50K以上",
      ],
      rongzi: [
        "不限",
        "未融资",
        "天使轮",
        "A轮",
        "B轮",
        "C轮",
        "D轮及以上",
        "已上市",
        "不需要融资",
      ],
      cityzp: [
        "北京招聘",
        "上海招聘",
        "广州招聘",
        "深圳招聘",
        "杭州招聘",
        "天津招聘",
        "西安招聘",
        "苏州招聘",
        "武汉招聘",
        "厦门招聘",
        "长沙招聘",
        "成都招聘",
        "郑州招聘",
        "重庆招聘",
        "佛山招聘",
        "合肥招聘",
        "济南招聘",
        "青岛招聘",
        "南京招聘",
        "东莞招聘",
      ],
      hotpo: [
        "北京抛光工招聘",
        "北京原画师招聘",
        "北京西点师招聘",
        "北京Python招聘",
        "北京校长/副校长招聘",
        "北京数学教师招聘",
        "北京汽车维修招聘",
        "北京游戏制作人招聘",
        "北京广告设计招聘",
        "北京智能驾驶系统工程师招聘",
        "北京人像修图师招聘",
        "北京政府关系招聘",
        "北京动画设计招聘",
        "北京销售顾问招聘",
        "北京康复治疗师招聘",
        "北京保险精算师招聘",
        "北京广告审核招聘",
        "北京仓库经理招聘",
        "北京全栈工程师招聘",
        "北京数据仓库招聘",
      ],
      hotcom: [
        "百度",
        "阿里巴巴集团",
        "腾讯",
        "BOSS直聘",
        "好未来",
        "软通动力",
        "京东数字科技",
        "高德地图",
        "华为",
        "VIPKID",
        "网易",
        "美菜",
        "新意互动",
        "易车",
        "纳恩博",
        "文思海辉",
        "什么值得买",
        "京东商城",
        "货拉拉科技",
        "中软国际",
        "掌门1对1",
        "饿了么",
        "学霸君",
        "亚信科技",
        "一点资讯",
        "中原地产",
        "美团网",
        "永航科技",
        "梆梆安全",
        "北京万科物业",
        "中信银行",
        "寺库",
        "腾信软创",
        "The ONE",
        "GrowingIO",
        "尚德机构",
        "51CTO",
        "百合网",
        "河狸家",
        "好大夫在线",
      ],
      nearby: [
        "石家庄招聘信息",
        "保定招聘信息",
        "张家口招聘信息",
        "承德招聘信息",
        "唐山招聘信息",
        "廊坊招聘信息",
        "沧州招聘信息",
        "衡水招聘信息",
        "邢台招聘信息",
        "邯郸招聘信息",
        "秦皇岛招聘信息",
      ],
    };
  },
  mounted() {
    this.nowstates();
    this.city();
    this.gshy();
  },
  methods: {
     //筛选
    shaixuan() {
      this.$axios({
        method: "get",
        url: "http://8.131.78.129:3000/boss/getdata?name=screen",
        data: {
          ...this.info,
        },
      })
        .then((response) => {
          console.log(response, "success"); // 成功的返回
          this.screen = response.data.data;
          console.log(this.screen);
        })
        .catch((error) => console.log(error, "error"));
    },
    city() {
      this.$axios({
        method: "get",
        url: "http://8.131.78.129:3000/boss/getdata?name=cityGroup ",
        data: {
          ...this.info,
        },
      })
        .then((response) => {
          console.log(response, "success"); // 成功的返回
          this.cityGroup = response.data.data;
          console.log(this.cityGroup);
          //     this.$store.commit("denglu", this.tel);
          // this.$router.push("/start2");
        })
        .catch((error) => console.log(error, "error"));
    },
    nowstates() {
      this.$axios({
        method: "get",
        url: "http://8.131.78.129:3000/boss/getdata?name=workingStatus ", // 接口地址
        data: {
          ...this.info,
        },
      })
        .then((response) => {
          console.log(response, "success"); // 成功的返回
          this.nowstate = response.data.data;
          console.log(this.nowstate);
        })
        .catch((error) => console.log(error, "error")); // 失败的返回
    },
    //公司行业
    gshy() {
      this.$axios({
        method: "get",
        url: "http://8.131.78.129:3000/boss/getdata?name=oldindustry ",
        data: {
          ...this.info,
        },
      })
        .then((response) => {
          console.log(response, "success"); // 成功的返回
          this.datas = response.data.data;
          console.log(this.datas);
          //     this.$store.commit("denglu", this.tel);
          // this.$router.push("/start2");
        })
        .catch((error) => console.log(error, "error"));
    },
    qhcsm(name) {
      console.log(name);
      (this.cityGroup.locationCity.name = name),
        (this.dialogTableVisible = false);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    person() {
      this.$router.push("./Person");
    },
    zhiwei() {
      this.$router.push("./Zhiwei-top");
    },
    show() {
      var mychar = document.getElementById("ui-select-dropdown");
      if (mychar.style.display == "block") {
        mychar.style.display = "none";
      } else {
        mychar.style.display = "block";
      }
    },
    qh(v) {
      var mychar = document.getElementById("ui-select-dropdown");
      console.log(v);
      this.defcity = v;
      if (mychar.style.display == "block") {
        mychar.style.display = "none";
      } else {
        mychar.style.display = "block";
      }
    },
      gshyqh(name) {
      this.dgshy = name;
    },
    gzjyqh(name) {
      this.gzjy = name;
      console.log(this.gzjy);
    },
    xlyqqh(name) {
      this.xlyq = name;
      console.log(this.xlyq);
    },
    xzyqqh(name) {
      this.xzyq = name;
    },
    handleSelect() {},
    rzjdqh(name) {
      this.rzjd = name;
    },
    gsgmqh(name) {
      this.gsgm = name;
    },
    pubtimeqh(name) {
      this.pubtime = name;
    },
    qk() {
      this.gzjy = "工作经验";
      this.xlyq = "学历要求";
      this.xzyq = "薪资要求";
      this.rzjd = "融资阶段";
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-button {
  border: none;
  width: 70px;
  padding-left: 5px;
  color: #5fd5c9;
}
.filter-box /deep/.el-icon-arrow-down:before {
  content: none;
}
.el-icon-search {
  color: white;
  background-color: #5fd5c9;
  width: 100px;
  height: 40px;
  float: right;
  text-align: center;
  line-height: 40px;
  color: gainsboro;
  font-size: 25px;
  font-weight: 500;
}
.sousuo {
  width: 100%;
  height: 40px;
  border: 1px solid #5fd5c9;
  margin-bottom: 10px;
  float: left;
  text-align: left;
  text-indent: 1em;
  background-color: white;
}
.sousuo input {
  width: 460px;
  border: 0;
  outline: none;
  height: 28px;
  line-height: 28px;
  padding-top: 6px;
}
.sousuo .xiala {
  width: 150px;

  line-height: 40px;
  color: white;
  outline: none;
}
.el-icon-search {
  background-color: #5fd5c9;
  width: 100px;
  height: 40px;
  float: right;
  text-align: center;
  line-height: 40px;
  color: gainsboro;
  font-size: 25px;
  font-weight: 500;
}
.el_zong {
  width: 90%;
}
.huixian {
  border-right: 1px solid gainsboro;
  height: 150px;
}
/deep/.el-input--suffix .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 0px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  margin-left: 20px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 62%;
}
/deep/.el-tabs__item.is-active {
  color: #000;
  border-bottom: 1px solid #5fd5c9;
}
/deep/.el-tabs__item is-top:active .el-tabs__item {
  color: #5fd5c9;
}
/deep/.el-tabs__item {
  padding: 0 35px;
  background-color: rgb(236, 235, 235);
}
/deep/.el-tabs__nav {
  white-space: nowrap;
  position: relative;
  // transition: transform 0.3s;
  /* float: left; */
  z-index: 2;
  width: 88%;
  margin-left: 69px;
  background-color: white;
  height: 50px;
}
/deep/.el-tabs__nav:hover .el-tabs__item {
  color: #000;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: white;
}
/deep/ .el-tabs__nav {
  white-space: nowrap;
  position: relative;
  margin-left: 45px;
  transition: transform 0.3s;
  /* float: left; */
  z-index: 2;
}
/deep/.el-tabs__active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: white;
  z-index: 1;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
}
.el-icon-caret-bottom {
  color: gray;

  margin-left: 5px;
}
.top_l .el-icon-caret-bottom {
  display: none;
}
/deep/.el-button:focus,
/deep/.el-button:hover {
  background: #fff;
}
/deep/.el-button--text {
  text-indent: 1em;
  float: left;
}
.top {
  background-color: #20232c;
  height: 50px;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  .el-button {
    background-color: #20232c;
    border: 0;
    color: white;
  }
}
.top ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -160px;
}
.top ul li {
  margin: 0 15px;
  font-size: 14px;
}
.top li:hover {
  color: #5fd5c9;
  cursor: pointer;
}
.top .changecity:hover {
  color: white;
}
.changecity span {
  font-size: 12px;
}
.boss {
  color: #5fd5c9;
}
.zhuce {
  border-radius: 30px;
  width: 60px;
  height: 30px;
  background-color: #20232c;
  color: white;
  border: 1px solid #5fd5c9;
}
.zhuce:hover {
  color: #5fd5c9;
  cursor: pointer;
}
a,
a:hover {
  text-decoration: none;
}
.main {
  width: 1184px;
  margin: 0 auto;
  min-height: calc(100vh - 300px);
}
.container {
  padding-top: 20px;
  .user-sider {
    float: right;
    width: 284px;
  }
}
.user-sider {
  float: right;
  width: 284px;
  font-size: 14px;
  .userinfo-box {
    text-align: center;
    .headbox {
      position: relative;
      height: 68px;
      .headimg {
        background-color: #f6f6f8;
        width: 60px;
        height: 60px;
        border-radius: 200px;
      }
      .sex {
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url(https://static.zhipin.com/zhipin-geek/v321/web/geek-vue/static/images/icons-sex.aceb70bb.png)
          no-repeat;
        background-size: 14px auto;
        position: absolute;
        margin-left: 46px;
        top: 0;
        background-position: 0 -25px;
      }
    }
    a {
      color: #414a60;
    }
    .edit-userinfo {
      position: absolute;
      top: 20px;
      right: 30px;
      z-index: 1;
      line-height: 1;
    }
  }
  .sider-box {
    background: #fff;
    padding: 20px 30px;
    margin-bottom: 16px;
    position: relative;
  }
}
.username {
  line-height: 40px;
  .icon-vip {
    background-position: 0 -40px;
    cursor: pointer;
    display: inline-block;
    width: 56px;
    height: 16px;
    background: url(https://static.zhipin.com/zhipin-geek/v321/web/geek-vue/static/images/icons-vip.89e6eb2b.png)
      no-repeat;
    background-image: image-set(url(https://static.zhipin.com/zhipin-geek/v321/web/geek-vue/static/images/icons-vip.89e6eb2b.png) 1x,url(https://static.zhipin.com/zhipin-geek/v321/web/geek-vue/static/images/icons-vip@2x.c4161a5e.png) 2x);
    vertical-align: middle;
    margin: -2px 0 0 8px;
  }
}

.userinfo {
  p {
    font-size: 12px;
    span {
      padding: 0 4px;
    }
  }
}
.now-state {
  width: 100%;
  position: relative;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
  cursor: pointer;
  border: 1px solid #e3e7ed;
  .ul-select-selection:hover {
    border: 1px solid #00c2b3;
    width: 100%;
    height: 30px;
  }
  .now-state:active {
    width: 100%;
    height: 30px;
    border: 1px solid #00c2b3;
  }
}
/deep/.el-popper {
  width: 300px;
}
#ui-select-dropdown {
  //  display: none;
  width: inherit;
  max-height: 200px;
  overflow: auto;
  margin: 1px 0 0;
  padding: 5px 0;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 0;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
  li {
    cursor: pointer;
  }
  li:hover {
    background-color: #f2f5fa;
    color: #00c2b3;
  }
}
.user-stat {
  display: flex;
  margin: 16px -15px -5px;
  flex-direction: row;
  a {
    font-size: 12px;
    margin: 0 13px;
    color: #8d92a1 !important;
    display: flex;
    flex-direction: column;
    p {
      font-size: 15px;
      color: #000;
    }
  }
}
.sider-resume {
  text-align: left;
  .resume-online {
    margin-top: 10px;
    p {
      line-height: 30px;
    }
    .edit {
      color: #8d92a1;
      margin-left: 5px;
    }
    .edit:hover {
      color: #00c2b3;
      cursor: pointer;
    }
    .upt-time {
      color: #8d92a1;
      font-size: 12px;
    }
  }
  .resume-attachment {
    height: 80px;
    border-bottom: 1px solid #eef0f5;
  }
}
.btn-primary {
  background-color: #5fd5c9;
  border-color: #5dd5c8;
  color: #fff;
  width: 100%;
  height: 35px;
  outline: none;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid #e3e7ed;
}
.sider-recommend {
  text-align: left;
  color: #000;
  a {
    color: #000;
    line-height: 40px;
    .yi {
      border-bottom: 1px solid #edf0f6;
    }
    i {
      float: right;
      margin-top: 10px;
    }
  }
  a:hover {
    color: #5fd5c9;
  }
}
.sider-vip {
  text-align: left;
  background-image: url(https://static.zhipin.com/zhipin-geek/v324/web/geek-vue/static/images/geek-vip-background.a93dc051.png)
    no-repeat 176px 8px #fff;
  li {
    margin: 12px 0 0;
    white-space: nowrap;
    font-size: 13px;
    color: #61687c;
    line-height: 30px;
    .vip-icon-resume {
      background-position: 0 0;
    }
    .vip-icon-data {
      background-position: 0 -50px !important;
    }
    .vip-icon-vip {
      background-position: 0 -100px !important;
    }
    .vip-icon-diamonds {
      background-position: 0 -150px !important;
    }
    .vip-icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      vertical-align: middle;
      background: url(https://static.zhipin.com/zhipin-geek/v324/web/geek-vue/static/images/geek-vip-icons.c4156031.png)
        no-repeat;
      background-image: image-set(url(https://static.zhipin.com/zhipin-geek/v324/web/geek-vue/static/images/geek-vip-icons.c4156031.png) 1x,url(https://static.zhipin.com/zhipin-geek/v324/web/geek-vue/static/images/geek-vip-icons@2x.9a1aa6b8.png) 2x);
      margin-right: 20px;
    }
  }
}
.refresh-lines {
  text-align: left;
  .refresh-text {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1;
    color: #8d92a1;
    .refresh-free-count {
      color: #53cac3;
      margin-left: 10px;
    }
  }
  h4 {
    float: left;
    margin-top: -5px;
    font-weight: 400 !important;
  }
  .svg-sub {
    float: right;
    display: flex;
    flex-direction: row;
    p {
      display: flex;
      flex-direction: row;
      line-height: 10px;
      color: #9fa3af;
      margin-left: 5px;
      font-size: 13px;
    }
    .gdot {
      width: 5px;
      height: 5px;
      display: block;
      border-radius: 50%;
      margin-right: 4px;
      background-color: #5fd5c9;
    }
    .ydot {
      width: 5px;
      height: 5px;
      display: block;
      border-radius: 50%;
      margin-right: 4px;
      background-color: #f8c579;
    }
  }
}
.user-jobs-area {
  margin-right: 300px;
}
.filter-box {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  .city-wrapper {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    line-height: 43px;
    i {
      line-height: 45px;
      color: gainsboro;
    }
    p {
      margin-right: 20px;
      i {
        margin-left: 5px;
      }
    }
    .selected {
      color: #fc6c38;
      padding-left: 3px;
      width: 40px;
      line-height: 30px;
      margin-top: 10px;
    }
  }
  .hotcity {
    li {
      float: left;
      margin: 0 6px;
    }
    li:hover {
      color: #fc6c38;
      cursor: pointer;
    }
  }
  .allcity {
    margin-left: 100px;
  }
  .allcity:hover {
    color: #fc6c38;
    cursor: pointer;
  }
  .xialas {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    /deep/.el-row {
      width: 150px;
      margin: 10px;
    }
    /deep/.el-dropdown {
      font-size: 12px;
    }
  }
}
.job-list {
  background-color: white;
  width: 72%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}
.job-box {
  width: 1230px;
  .job-list {
    height: 130px;
    margin-bottom: 20px;
  }
}
/deep/.el-row:hover {
  cursor: pointer;
}
.job-primary {
  padding: 0 20px;
  .red-money {
    color: #fc6c38;
  }
  img {
    width: 56px;
    height: 56px;
    border-radius: 10px;
  }
}
.info-primary {
  img {
    width: 13px;
    height: 13px;
  }
  span {
    color: #61687c;
    font-size: 14px;
  }
}
.job-primary-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}
.job-primary-top div {
  flex-grow: 1;
}
.job-primary span {
  margin: 0 5px;
}
.job-primary p {
  line-height: 30px;
}
.job-primary-bottom {
  background-color: #f8f9fc;
  height: 30px;
  width: 100%;
  margin-left: -20px;
  padding: 10px 20px;
  line-height: 30px;
  .kuang {
    float: left;
    border: 1px solid #eee;
    margin-right: 10px;
    font-size: 13px;
    height: 23px;
    color: #61687c;
    line-height: 23px;
    padding: 0 5px;
  }
}
.list {
  float: right;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 350px;
  display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
  -webkit-line-clamp: 1;
  font-size: 13px;
  margin-right: -3px;
  p {
    float: left;
    margin-right: 2px;
  }
}
//分页
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #62d5c8;
  color: white;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: white;
  background-color: #62d5c8;
}
.info-publis {
  width: auto;
  text-align: left;
  padding: 0 10px;
  border: 1px solid #eee;
  border-radius: 12px;
  height: 21px;
  line-height: 21px;
  margin-top: 1px;
}

.primary-right {
  margin-right: -181px;
  width: 220px;
  p {
    text-align: left;
  }
}
.sider {
  float: right;
  img {
    width: 284px;
    height: 160p;
  }
  .complete-resume {
    background-color: #fff;
    margin-bottom: 16px;
    width: 280px;
    height: 80px;
    line-height: 76px;
    button {
      width: 224px;
      height: 34px;
      background-color: #62d5c8;
      border: 0;
      color: white;
    }
  }
}
.sider-list {
  background-color: #fff;
  margin-bottom: 16px;
  li {
    margin: 0 30px;
    border-bottom: solid 1px #eef0f5;
    padding: 8px 0 2px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    p {
      text-align: left;
      line-height: 22px;
      color: #8d92a1;
      font-size: 12px;
      margin-bottom: 6px;
    }
    .salary {
      font-weight: 400;
      color: #fc6c38;
      position: relative;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    a {
      text-decoration: none;
      color: #414a60;
      text-align: left;
      margin-right: 30px;
      width: 150px;
    }
  }
  h3 {
    height: 45px;
    display: block;
    line-height: 45px;
    font-size: 15px;
    color: #54cbc4;
    font-weight: 400;
    text-align: left;
    text-indent: 2em;
    border-bottom: solid 2px #f2f2f5;
  }
}
/deep/.el-tabs__nav-scroll {
  height: 42px;
  width: 750px;
  margin-left: -70px;
  background-color: rgb(236, 235, 235);
}
/deep/.el-tabs__content {
  height: 500px;
}
.section-city {
  margin-left: 0;
  padding: 20px 0;
  height: 378px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 720px;
}
.section-citys li {
  float: left;
  line-height: 40px;
  width: 20%;
  cursor: pointer;
  text-align: center;
  margin-bottom: 8px;
}
/deep/.el-dialog__title {
  margin-right: 500px;
}
/deep/.el-dialog {
  width: 60%;
}
.ae {
  width: 720px;
  height: 350px;
  margin: 0 auto;
  overflow: hidden;
  overflow-y: scroll;
  .aeitem {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: row;
  }
}
.aeiteml {
  width: 10%;
  height: auto;
  color: #5dd5c8;
}
.aeitemr {
  width: 90%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}
.aeitemss {
  width: 20%;
  height: auto;
  padding-bottom: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.aeitemss:hover {
  color: #5dd5c8;
  cursor: pointer;
}
.hot-city span {
  display: block;
  width: 100%;
  height: 100%;
}
.hot-city {
  float: left;
  line-height: 40px;
  width: 20%;
  cursor: pointer;
  text-align: center;
  margin-bottom: 8px;
}
.duihuak li {
  float: left;
  margin: 20px;
  cursor: pointer;
}
/deep/.el-popover {
  height: 300px;
  left: 17px !important;
  position: absolute;
}
/deep/.el-popper[x-placement^="bottom"] {
  left: 17px !important;
  position: absolute;
}
.last {
  height: 260px;
  background-color: white;
}
.last_l {
  display: flex;
  flex-direction: row;
  margin: 20px;
}
.last_l ul {
  float: left;
  margin: 20px;
}
.last_l ul li {
  line-height: 23px;
  text-align: left;
  color: gray;
  font-size: 12px;
}
.last_l ul li:first-child {
  line-height: 40px;
  color: #000;
}
.last_b_lv span:hover {
  color: #5fd5c9;
  cursor: pointer;
}
.last_r {
  text-align: right;
  float: right;
  margin-left: 460px;
  color: gray;
  p {
    font-size: 13px;
  }
}
.last_b {
  text-align: left;
  margin-left: 40px;
  font-size: 12px;
}
.last_b p {
  line-height: 30px;
}
.last_b p:first-child {
  color: gray;
}
.last_b span {
  margin: 6px;
}
.jingguo:hover {
  color: #5fd5c9;
  cursor: pointer;
}
.icon-weibo img {
  width: 40px;
  height: 40px;
  border: 1px solid gainsboro;
  border-radius: 50%;
}
.icon-weibo {
  float: right;
  margin-left: 40px;
  margin-bottom: 150px;
}
.icon-weibo img:hover {
  border: 1px solid gray;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.last_ula li:hover,
.last_ulb li:hover {
  color: #5fd5c9;
}
.qiehuan {
  display: flex;
  color: #9fa3b0;
  padding: 0 15px;
  flex-direction: row;
  line-height: 44px;
  height: 46px;
  cursor: pointer;
  border-bottom: 2px solid #f2f2f5;
  background-color: #fff;
}
.qiehuan:hover {
  color: #5fd5c9;
}
.active {
  color: #5fd5c9;
  border-bottom: 2px solid #5fd5c9;
}
.tel .el-dropdown {
  color: white;
}

.xiahuaxian {
  border-bottom: 1px solid gainsboro;
  height: 30px;
  font-size: 12px;
  color: grey;
  line-height: 30px;
}
.ui-select-selection:hover {
  border: 1px solid #5fd5c9;
}
.dixiaall {
  width: 100%;
  height: auto;
  background-color: #fff;
}
.actives {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 1003;
  background: #fff;
  z-index: 1004;
}
.dixia {
  width:96%;
  height: 45px;
  margin: 0 auto;
  .zuob {
    width: 13.5%;
    height: 45px;
    float: left;
    .el-icon-caret-bottom {
      margin-top: 17px;
    }
  }
}

/deep/.el-submenu {
  height: 45px;
}
/deep/.el-submenu__title {
  margin-top: -6px;
}
.zongtow1 {
  position: absolute;
  width: 10%;
  height: 45px;
  float: left;
  margin-left: 75%;
  margin-top: 13px;
  font-size: 14px;
  color: dimgray;
  cursor: pointer;
}
/deep/.el-menu--horizontal > .el-submenu .el-submenu__title {
  border-bottom: none;
  color: #000;
  font-size: 12px;
}
/deep/.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: none;
}
.zc {
  width: 100%;
  height: auto;
  background-color: #5dd5c8;
}
</style>
<style>
body {
  color: #414a60;
}
</style>