<template>
	<div class="main">
		<div class="bg-white" style="padding: 20px 0;">
			<div class="top_cate">
				<div class="top_course">
					<span>课程:</span>
					<span @click="changeStatus(item)" :key="index" :class="checkid==item.title?'blue':''"
						v-for="(item,index) in typeList">{{item.title}}</span>
				</div>
				<div class="top_course">
					<span>分类:</span>
					<span v-if="item2.title" @click="changeCate(item2.id)" :class="sonid==item2.id?'blue':''"
						v-for="item2 in sonList">{{item2.title}}</span>
				</div>
			</div>

		</div>
		<div class="books_wrapper">
			<div class="books">
				<div @click="open(item)" :key="item.id" v-for="item in pdfList" class="book_item">
					<div class="flex align-center flex1">
						<img class="book_img" src="../../assets/icon/book.png" alt="" />
						{{item.title}}
					</div>
					<img class="book_right" src="../../assets/icon/right.png" alt="" />
				</div>
			</div>
			<!-- <el-pagination background layout="prev, pager, next" :total="1000">
			</el-pagination> -->
		</div>
		<!-- 	<el-pagination background layout="prev, pager, next" :total="1000">
		</el-pagination> -->
	</div>
</template>

<script>
	import {
		HttpRequest
	} from '@/utils/http';
	import {
		mapState
	} from "vuex";
	import {
		Message
	} from "element-ui";
	export default {
		name: "view",
		data() {
			return {
				currentPage: 1,
				checkid: '',
				sonid: '',
				type_name: '',
				typeList: [],
				sonList: [],
				courseList: [],
				pdfList: []
			}
		},
		mounted() {
			this.getCourseCate()
		},
		methods: {
			open(e) {
				window.open(e.pdfUrl);
			},
			goVideo(id) {
				this.$router.push('../Catalog?id=' + id);
			},
			getCourseCate() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/home/pdfTopic"
				}).then(res => {

					this.typeList = res.data.data
					if (this.sonid == '') {
						this.sonid = res.data.data[0].list[0].id
					}
					if (this.checkid != '') {
						console.log(res.data.data.length, "res.data.data.length")
						if (res.data.data.length > 0) {
							res.data.data.forEach(item => {
								console.log(item.title == this.checkid)
								if (item.title == this.checkid) {
									this.sonList = item.list
								}
							})
						}
					} else {
						this.sonList = res.data.data[0].list
						this.checkid = res.data.data[0].title
					}
					this.getCourse()
				}).catch(err => {});
			},
			changeStatus(e) {
				this.sonList = e.list
				this.checkid = e.title
				this.sonid = this.sonList[0].id
				// window.open(
				// 	'http://jayunketang.oss-cn-beijing.aliyuncs.com/2023/12/12/eb3488576f38446289f56a32b6c9669d.pdf');
				this.getCourse()
			},
			getCourse() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/home/coursewareList?typeId=" + this.sonid
				}).then(res => {
					console.log(res.data.data)
					this.pdfList = res.data.data
				});
			},
			changeCate(e) {
				this.sonid = e
				this.getCourse()
			},
		}
	}
</script>

<style lang="less" scoped>
	.blue {
		color: #227BF9;
	}

	.top_cate {
		border: 1px solid #EBEBEB;
		width: 70%;
		margin: 0 auto;
		border-radius: 10px;
		text-align: left;
		line-height: 45px;
	}

	.top_course {
		padding: 0 20px;
	}

	.top_course:first-child {
		border-bottom: 1px solid #EBEBEB;
	}

	.top_course span {
		margin-right: 20px;
		cursor: pointer;
	}

	.books {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.book_item {
		width: 47%;
		padding: 0 15px;
		border-radius: 5px;
		background-color: #fff;
		margin: 10px 0;
		height: 64px;
		line-height: 64px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;


	}

	.book_img {
		width: 34px;
	}

	.book_right {
		width: 25px;
	}

	.book_item2 {
		width: 100%;
		border-radius: 5px;
		background-color: #fff;
		margin: 10px 0;
		height: 64px;
		line-height: 64px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.3);
		justify-content: space-between;
	}

	.books_wrapper {
		min-height: 540px;
		width: 70%;
		margin: 0 auto;
	}
</style>