<template>
	<div>
		<div class="main">
			<div style="min-height: 550px;">
				<div class="con">
					<div class="title">
						公告
					</div>
					<div @click="goDe(item.id)" class="notice_item" :key="item.id" v-for="item in noticeList">
						<div>
							<span>{{item.title}}</span>
							<div class="margin-top-sm text-gray">{{item.createTime?item.createTime:'暂无'}}</div>
						</div>
						<img class="icon" src="../../assets/icon/jt_hei.png" alt="" />
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		HttpRequest
	} from '@/utils/http';

	import {
		mapState
	} from "vuex";
	import {
		Message
	} from "element-ui";
	export default {
		name: 'notice',
		data() {
			return {
				pageInfo: {
					pageNum: 1,
					pageSize: 10,
					total: 0
				},
				noticeList: []
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				HttpRequest.getRequest({
					method: "POST",
					data: this.pageInfo,
					url: "/app/api/home/getAnnouncementList"
				}).then(res => {
					this.noticeList = res.data.data.rows
					this.pageInfo.total = res.data.data.total
				})
			},
			goDe(e) {
				this.$router.push('../noticeDe?id=' + e);
			}
		}
	}
</script>

<style lang="less" scoped>
	.main {
		width: 70%;
		margin: 20px auto;
	}

	.con {
		background-color: #fff;
		border-radius: 6px;
		padding: 0 15px;

		.title {
			text-align: left;
			border-bottom: 1px solid #F1F1F1;
			padding: 20px 0;
		}
	}

	.notice_item {
		text-align: left;
		padding: 15px 0;
		justify-content: space-between;
		align-items: center;
		display: flex;
		border-bottom: 1px solid #F1F1F1;

		.icon {
			width: 23px;
		}
	}
</style>