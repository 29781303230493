<template>
  <div class="zong">
      <p>您访问的页面不存在~</p>
      <router-link to="./Start"><button>首页</button></router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.zong{
    margin:200px auto;
    text-align: center;
    button{
        width:80px;
        border:1px solid #5dd5c8;
        height:30px;
        outline:none;
    }
}
</style>