<template>
	<div>
		<div class="main">
			<p class="nav"><span @click="goHome()">首页</span>><span @click="goMine()">个人中心</span>>设置</p>
			<div class="con">
				<div class="con_l">
					<div @click="changCate(index)" :key="index" v-for="(item,index) in cateList" class="cate_item"
						:style="cateIndex==index?'backgroundColor:#227BF9;color:#fff':''">
						<div class="flex align-center flex1">
							<img class="cate_icon" :src="cateIndex==index?item.icon2:item.icon" alt="" />
							<span>{{item.name}}</span>
						</div>
						<img class="jt"
							:src="cateIndex==index?require('../../src/assets/icon/jt_bai@2x.png'):require('../../src/assets/icon/jt_hei.png')"
							alt="" />
					</div>
				</div>
				<div class="con_r" v-if="cateIndex!=3&&cateIndex!=4">
					<div class="person" v-if="cateIndex==0">
						<div class="flex">
							<p>头像</p>
							<div style="width: 100%;">
								<el-upload class="avatar-uploader"
									action="http://222.75.31.198:8080/app/api/my/upload"
									:headers="headerObj" :show-file-list="false" :on-success="handleAvatarSuccess"
									:before-upload="beforeAvatarUpload">
									<img v-if="userInfo.headImage" :src="userInfo.headImage" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
							</div>

						</div>
						<div class="flex">
							<p>姓名</p>
							<el-input placeholder="请输入姓名" v-model="userInfo.name" clearable>
							</el-input>
						</div>
						<!--						<div class="flex">-->
						<!--							<p>ID</p>-->
						<!--							<el-input placeholder="请输入姓名" display v-model="userInfo.id" clearable>-->
						<!--							</el-input>-->
						<!--						</div>-->
						<div class="flex">
							<p>手机号</p>
							<el-input readonly type="number" placeholder="请输入手机号" v-model="userInfo.phone" clearable>
							</el-input>
						</div>
						<div class="flex">
							<p>所属县</p>
							<div style="width:100%">
								<el-select v-model="userInfo.enterpriseId" placeholder="请选择" @change="getSelectDept">
									<el-option v-for="item in enterList" :key="item.deptId" :label="item.deptName"
										:value="item.deptId">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="flex">
							<p>我的企业</p>
							<div style="width:100%">
								<el-select v-model="userInfo.departmentId" placeholder="请选择" @change="getSelectWork">
									<el-option v-for="item in departList" :key="item.deptId" :label="item.deptName"
										:value="item.deptId">
									</el-option>
								</el-select>
							</div>

						</div>
						<div class="flex">
							<p>所属车间</p>
							<div style="width:100%">
								<el-select v-model="userInfo.workshopId" placeholder="请选择" @change="getSelectTeam">
									<el-option v-for="item in workshopList" :key="item.deptId" :label="item.deptName"
										:value="item.deptId">
									</el-option>
								</el-select>
							</div>

						</div>
						<div class="flex">
							<p>所属小组</p>
							<div style="width:100%">
								<el-select v-model="userInfo.teamId" placeholder="请选择">
									<el-option v-for="item in teamList" :key="item.deptId" :label="item.deptName"
										:value="item.deptId">
									</el-option>
								</el-select>
							</div>

						</div>
						<div class="flex">
							<p>身份证号</p>
							<el-input placeholder="请输入身份证号" v-model="userInfo.idCard" clearable>
							</el-input>
						</div>
						<!-- <div class="flex">
							<p>所属单位</p>
							<el-input placeholder="请输入所属单位" v-model="userInfo.affiliatedUnit" clearable>
							</el-input>
						</div> -->
						<div class="publish" @click="updateUserInfoFun">保存</div>
					</div>
					<div class="user" v-if="cateIndex==1">
						<div class="about" style="text-align: left">
							<span style="white-space:pre-wrap;"> {{richText}}</span>
						</div>

					</div>
					<div class="user" v-if="cateIndex==2">
						<div class="about" style="text-align: left">
							<span style="white-space:pre-wrap;"> {{richText}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="注销提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span>注销后无法恢复，确认注销手机号码为：{{userInfo.phone}}的账号？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="zhuxiaoFun">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		HttpRequest
	} from '@/utils/http';
	import router from '../router/index.js'
	export default {
		data() {
			return {
				dialogVisible: false,
				richText: '',
				headerObj: {
					'appToken': localStorage.getItem("token")
				},
				userInfo: {},
				cateIndex: 0,
				imageUrl: '',
				form: {
					name: ''
				},
				cateList: [{
						name: '个人资料',
						icon: require('../../src/assets/icon/Group 4171@2x.png'),
						icon2: require('../../src/assets/icon/Group 4168@2x.png'),
					},
					{
						name: '用户协议',
						icon: require('../../src/assets/icon/Group 4173@2x.png'),
						icon2: require('../../src/assets/icon/Group 4172@2x.png'),
					},
					{
						name: '隐私政策',
						icon: require('../../src/assets/icon/Group 4166@2x.png'),
						icon2: require('../../src/assets/icon/Group 4170@2x.png'),
					},
					{
						name: '注销账号',
						icon: require('../../src/assets/icon/Group 4165@2x.png'),
						icon2: require('../../src/assets/icon/Group 4169@2x.png'),
					},
					{
						name: '退出登录',
						icon: require('../../src/assets/icon/exit.png'),
						icon2: require('../../src/assets/icon/exit_check.png'),
					}
				],
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}],
				value: '',
				enterList: [],
				departList: [],
				workshopList: [], //车间
				teamList: [], //小组
			}
		},
		created() {
			this.getUserInfo()
			this.getEnter();
		},
		methods: {
			getUserInfo() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/home/getUserInfo"
				}).then(res => {
					console.log(res.data.data)
					this.userInfo = res.data.data
					this.getDepart()
					this.getWorkshop()
					this.getTeam()
				})
			},
			getWorkshop() {
				HttpRequest.getRequest({
					method: "POST",
					url: '/app/login/departmentList?deptId=' + this.userInfo.departmentId,
				}).then(res => {
					this.workshopList = res.data.data
					console.log(this.workshopList, 78)
				})
			},
			getTeam() {
				HttpRequest.getRequest({
					method: "POST",
					url: '/app/login/departmentList?deptId=' + this.userInfo.workshopId,
				}).then(res => {
					this.teamList = res.data.data
				})
			},
			goHome() {
				this.$router.push('/Start');
			},
			goMine() {
				this.$router.push('../my');
			},
			handleClose() {
				this.dialogVisible = false
			},
			//企业列表
			getEnter() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/login/enterpriseList"
				}).then(res => {
					this.enterList = res.data.data
				})

			},
			//部门列表
			getDepart() {
				HttpRequest.getRequest({
					method: "POST",
					url: '/app/login/departmentList?deptId=' + this.userInfo.enterpriseId,
				}).then(res => {
					this.departList = res.data.data
				})
			},
			//重新选择了企业，清空部门
			getSelectDept(val) {
				this.userInfo.enterpriseId = val
				this.userInfo.departmentId = '';
				this.userInfo.workshopId = '';
				this.userInfo.teamId = '';
				this.getDepart(val);
			},
			getSelectWork(val) {
				this.userInfo.departmentId = val
				this.userInfo.workshopId = '';
				this.userInfo.teamId = '';
				this.getWorkshop(val);
			},
			getSelectTeam(val) {
				this.userInfo.workshopId = val
				this.userInfo.teamId = '';
				this.getTeam(val);
			},
			//保存个人信息
			updateUserInfoFun() {
				var data = {
					headImage: this.userInfo.headImage,
					name: this.userInfo.name,
					phone: this.userInfo.phone,
					enterpriseId: this.userInfo.enterpriseId,
					departmentId: this.userInfo.departmentId,
					idCard: this.userInfo.idCard,
					affiliatedUnit: this.userInfo.affiliatedUnit,
					// typeWork: this.userInfo.typeWork,
					// idNumber: this.userInfo.idNumber,
					workshopId: this.userInfo.workshopId,
					teamId: this.userInfo.teamId
				}
				HttpRequest.getRequest({
					method: "POST",
					url: '/app/api/my/updateUserInfo',
					data: data
				}).then(res => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.getUserInfo()
				})
			},
			changCate(index) {
				this.dialogVisible = false
				console.log(index)
				this.cateIndex = index
				if (index == 1) {
					this.xieyiFun(2)
				}
				if (index == 2) {
					this.xieyiFun(3)
				}
				if (index == 3) {
					this.dialogVisible = true
				} else if (index == 4) {
					localStorage.clear()
					router.push({
						path: '/login'
					})
				}
			},

			handleAvatarSuccess(val) {
				console.log(val.data.url, val.code)
				if (val.code === 200) {
					this.userInfo.headImage = val.data.url

				}
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},

			xieyiFun(type) {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/login/getOther?type=" + type,
				}).then(res => {
					console.log(res.data)
					this.richText = res.data.data.richText
				}).catch(err => {});
			},

			zhuxiaoFun() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/my/accountDel",
				}).then(res => {
					router.push({
						path: '/login'
					})
				}).catch(err => {});
			}
		}
	}
</script>

<style scoped lang="less">
	.main {
		width: 70%;
		margin: 0 auto;
		text-align: left;

		.nav {
			margin: 20px 0;
			color: #999;

			span {
				cursor: pointer;
			}
		}
	}

	.con {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;

	}

	.main {
		min-height: 550px;
	}

	.con_l {
		background-color: #fff;
		border-radius: 5px;
		padding: 5px;
		width: 21%;
		max-height: 700px;

		.cate_item {
			margin: 10px 5px;
			display: flex;
			border-radius: 5px;
			padding: 15px 20px;
			align-items: center;
			justify-content: space-between;
		}

		.cate_icon {
			margin-right: 5px;
			width: 23px;
		}

		.jt {
			width: 20px;
		}
	}

	.con_r {
		background-color: #fff;
		width: 76%;
		border-radius: 10px;

		.avatar-uploader .el-upload {

			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.avatar-uploader .el-upload:hover {
			border-color: #409EFF;
		}

		.avatar-uploader-icon {
			border: 1px solid #d9d9d9;
			font-size: 28px;
			color: #8c939d;
			width: 88px;
			height: 88px;
			line-height: 88px;
			text-align: center;
		}

		.avatar {
			width: 88px;
			height: 88px;
			display: block;
		}

		.person {
			padding: 20px;
		}

		.flex {
			margin: 15px 0;
			align-items: center;

			p {
				color: #666;
				text-align: right;
				white-space: nowrap;
				width: 100px;
				margin-right: 22px;
			}
		}

		.publish {
			background-color: #1B8AFB;
			color: #fff;
			border-radius: 5px;
			width: 90px;
			height: 42px;
			text-align: center;
			line-height: 42px;
			margin-left: 110px;
		}
	}

	.date {
		color: #999;
		padding: 20px 0;
		border-bottom: 1px solid #F5F5F5;
	}

	.user {
		padding: 15px;
	}
</style>