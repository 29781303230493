<template>
	<div>
		<div class="main">
			<h2 class="h2">{{title}}</h2>
			<div class="top_nav">
				<div class="moni" @click="goMoni(0)">
					<img src="../../assets/icon/Group 105@2x.png" alt="" />
				</div>
				<div class="moni" @click="goMoni(1)">
					<img src="../../assets/icon/icon13.png" alt="" />
				</div>
				<div class="error" @click="goMine(2)">
					<img src="../../assets/icon/Group 111@2x.png" alt="" />
					<span>错题记录</span>
				</div>
			</div>
			<div class="footer">
				<div class="footer_item" @click="goExe(0)">
					<img src="../../assets/icon/Group 109@2x.png" alt="" />
					<span>顺序练习</span>
				</div>
				<div class="footer_item" @click="goExe(1)">
					<img src="../../assets/icon/Group 108@2x.png" alt="" />
					<span>随机练习</span>
				</div>
				<div class="footer_item" @click="goMine(1)">
					<img src="../../assets/icon/Group 112@2x.png" alt="" />
					<span>考试记录</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		HttpRequest
	} from '@/utils/http';

	import {
		mapState
	} from "vuex";
	import {
		Message
	} from "element-ui";
	export default {
		name: 'exHome',
		data() {
			return {
				swiperList: [],
				id: this.$route.query.id,
				title: this.$route.query.title
			}
		},
		mounted() {
			// this.getInfo()
		},
		methods: {

			goMoni(e) {
				this.$router.push('./imitate?id=' + this.id + '&title=' + this.title + '&type=' + e)
			},
			goExe(e) {
				this.$router.push('./exe?id=' + this.id + '&type=' + e + '&title=' + this.title)
			},
			goMine(e) {
				this.$router.push('../my?index=' + e);
			}
		}
	}
</script>

<style scoped lang="less">
	.h2 {
		text-align: left;
		margin: 20px 0;
	}

	.main {
		width: 70%;
		margin: 0 auto;

	}

	.top_nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.moni {
		border-radius: 6px;
		width: 32%;
		height: 240px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
	}

	.moni img {
		width: 200px;
	}

	.error {
		width: 32%;
		background-color: #fff;
		height: 240px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border-radius: 6px;
	}

	.error img,
	.footer_item img {
		width: 110px;
		margin-bottom: 20px;
	}

	.footer {
		margin: 20px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.footer_item {
		border-radius: 6px;
		height: 240px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 32%;
		background-color: #fff;
	}
</style>