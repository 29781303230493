<template>
	<div>
		<div class="main" style="min-height: 550px;">
			<div class="text-lg">
				{{Info.title}}
			</div>
			<div class="date text-gray margin-top-sm">
				{{Info.createTime}}
			</div>
			<div class="date">
				<div v-html="Info.richText"></div>
			</div>

		</div>

	</div>
</template>

<script>
	import {
		HttpRequest
	} from '@/utils/http';

	import {
		mapState
	} from "vuex";
	import {
		Message
	} from "element-ui";
	export default {
		name: 'noticeDe',
		data() {
			return {
				Info: {}
			}

		},
		mounted() {
			this.getInfo()
		},
		methods: {
			getInfo() {
				HttpRequest.getRequest({
					method: "POST",
					url: '/app/api/home/announcementInfo?id=' + this.$route.query.id,
				}).then(res => {
					this.Info = res.data.data
				})
			},
		}
	}
</script>

<style scoped>
	.main {
		padding: 20px;
		background-color: #fff;
		text-align: left;
		width: 70%;
		margin: 20px auto;
		border-radius: 10px;
	}
</style>