<template>
	<div>
		<div class="plan_con">
			<div class="con_l">
				<div @click="changCate(index)" :key="index" v-for="(item,index) in cateList" class="cate_item"
					:style="cateIndex==index?'backgroundColor:#227BF9;color:#fff':''">
					<div class="flex align-center hand flex1">
						<img class="cate_icon" :src="cateIndex==index?item.icon2:item.icon" alt="" />
						<span>{{ item.name }}</span>
					</div>
					<img class="jt"
						:src="cateIndex==index?require('../../src/assets/icon/jt_bai@2x.png'):require('../../src/assets/icon/jt_hei.png')"
						alt="" />
				</div>
			</div>
			<!-- <div>培训计划</div> -->
			<!-- <div class="date">2021.20</div> -->
			<div class="main_con" v-html="Info.richText"></div>
		</div>
	</div>
</template>

<script>
	import {
		HttpRequest
	} from '@/utils/http';

	import {
		mapState
	} from "vuex";
	import {
		Message
	} from "element-ui";
	export default {
		name: 'plan',
		data() {
			return {
				Info: {},
				cateIndex: 0,
				cateList: [{
						name: '查看PDF',
						icon: require('../../src/assets/icon/icon6.png'),
						icon2: require('../../src/assets/icon/icon5.png'),
					},
					{
						name: '学习课程',
						icon: require('../../src/assets/icon/icon7.png'),
						icon2: require('../../src/assets/icon/icon8.png'),
					},
					{
						name: '立即考试',
						icon: require('../../src/assets/icon/icon9.png'),
						icon2: require('../../src/assets/icon/icon10.png'),
					}
				],
			}
		},
		mounted() {
			this.getInfo()
		},
		methods: {
			changCate(e) {
				this.cateIndex = e
				if (e == 0) {
					HttpRequest.getRequest({
						method: "POST",
						url: "/app/api/course/selectPdf?pdfId=" + this.Info.pdfId
					}).then(res => {
						console.log(res.data.data)
						if (res.data.code === 200) {
							window.open(res.data.data.pdfUrl);
						}
						// this.Info = res.data.data
					})

				} else if (e == 1) {
					this.$router.push('../Catalog?id=' + this.Info.courseId);
				} else {
					this.$router.push('../exHome?id=' + this.Info.examTypeId + '&title=' + this.Info.examTypeName);
				}
			},
			getInfo() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/home/getTrainingPlanDetails"
				}).then(res => {
					console.log(res.data.data)

					this.Info = res.data.data
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.main_con {
		background-color: #fff;
		border-radius: 5px;
		padding: 15px 14px;
		width: 74%;
		max-height: 800px;
		overflow-y: scroll;

	}

	.plan_con {
		display: flex;
		display: flex;
		justify-content: space-between;
		// background-color: #fff;
		width: 70%;
		margin: 20px auto;
		text-align: left;
		border-radius: 20px;
		padding: 20px;
		min-height: 560px;
	}

	.date {
		padding: 12px 0;
		color: #999;
		border-bottom: 1px solid #F5F5F5;
	}

	.con_l {
		background-color: #fff;
		border-radius: 5px;
		padding: 5px;
		width: 21%;
		height: 220px;
		// max-height: 700px;

		.cate_item {
			margin: 10px 5px;
			display: flex;
			border-radius: 5px;
			padding: 15px 20px;
			align-items: center;
			justify-content: space-between;
		}

		.cate_icon {
			margin-right: 5px;
			width: 23px;
		}

		.jt {
			width: 20px;
		}
	}
</style>