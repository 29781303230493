<template>
	<div>
		<div class="top_video">
			<div class="video_wrapper">
				<div class="flex video_top align-center">
					<span class="video_tag tag_item" v-for="(item,index) in tags" :key="index">{{item}}</span>
					<span class="text-white">{{courseInfo.title}}</span>
				</div>
				<video controlslist="nodownload noplaybackrate timeline " controls @loadeddata="setInitialTime"
					:enable-progress-gesture="false" :show-progress="false" :initial-time="playInfo.playTime"
					class="my_video" ref="videoPlayer" id="video" :src="playInfo.videoUrl" @timeupdate="playJindu">
				</video>
				<!-- <video class="my_video" ref="video" id="video" :src="playInfo.videoUrl"></video> -->
				<div class="video_footer">
					<div class="flex align-center">
						<p>共<span class="blue">{{courseInfo.chapterNumber}}</span>节课</p>
						<p style="margin-left: 20px;"><span>{{courseInfo.virtuallyLearnersNumber}}</span>人学习</p>
						<img @click="handleColler" class="collect_icon"
							:src="isCollect?require('../../src/assets/icon/collect2.png'):require('../../src/assets/icon/collect.png')"
							alt="" />
					</div>
					<div @click="ewmShow=true" class="join_wrapper">
						和小伙伴一起学习，有老师答疑哦~
						<div class="join">加入群聊</div>
					</div>
				</div>
			</div>
		</div>
		<div class="con_cate">
			<div style="padding:10px 20px 0;">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="课程目录" name="0"></el-tab-pane>
					<el-tab-pane label="课程介绍" name="1"></el-tab-pane>
					<el-tab-pane :label="commTitle" name="2"></el-tab-pane>
				</el-tabs>
			</div>
			<div v-if="activeName==0">
				<div class="gengxin flex just-between align-center">
					<div>
						已更新至第<span class="red">{{list.length}}</span>节
					</div>
					<div>学习进度: <span class="red">{{courseProgress}}%</span></div>
				</div>
				<div style="max-height: 400px;overflow-y: scroll;">
					<div class="video_item" @click="playVideo(item)" :key="index" v-for="(item,index) in list">
						<div class="flex flex1 align-center">
							<img class="play_icon" src="../assets/icon/play.png" alt="" />
							<span style="flex: 1;text-align: left;"
								:class="playInfo==item?'blue':''">{{item.title}}</span>
						</div>
						<span class="red">{{item.progress!=0?item.progress+'%':'未观看'}}</span>
					</div>
				</div>

			</div>
			<div class="content" v-if="activeName==1">
				<div v-html="courseInfo.richText"></div>
			</div>
			<div v-if="activeName==2">
				<div class="rate">
					为课程评分:<el-rate v-model="commentInfo.number"></el-rate>
				</div>
				<el-input class="judge" type="textarea" :autosize="{ minRows: 2, maxRows: 7}" placeholder="请输入评价内容"
					v-model="commentInfo.title">
				</el-input>
				<div class="judge_footer">
					<div class="publish" @click="publishBtn()">发表评价</div>
				</div>
				<div class="judge_item" :key="item.id" v-for="item in pList">
					<img class="avatar" :src="item.headImage?item.headImage:require('../assets/icon/avatar.png')"
						alt="" />
					<div class="judge_con">
						<div class="flex align-center">
							{{item.name}}
							<el-rate disabled allow-half v-model="item.number"></el-rate>
						</div>
						<div style="margin: 20px 0;">
							{{item.title}}
						</div>
						<span class="text-gray">{{item.createTime?item.createTime:'暂无'}}</span>
					</div>
				</div>
				<el-pagination v-if="pageInfo.total>0" @current-change="currentChange" background
					layout="prev, pager, next" :total="pageInfo.total">
				</el-pagination>
			</div>
		</div>
		<el-dialog width="18%" class="ewm_pop" :show-close="false" :visible.sync="ewmShow">
			<img class="ewm" :src="courseInfo.customer" alt="" />
		</el-dialog>
	</div>
</template>

<script>
	import {
		HttpRequest
	} from '@/utils/http';
	import {
		mapState
	} from "vuex";
	import {
		Message
	} from "element-ui";
	export default {
		name: "Catalog",
		data() {
			return {
				ewmShow: false,
				isCollect: true, //是否收藏
				commTitle: "",
				activeName: 0,
				rate: 3,
				textarea: "",
				id: this.$route.query.id,
				courseInfo2: {},
				courseInfo: {},
				list: [],
				tags: [],
				playInfo: {},
				playState: 0,
				courseProgress: 0,
				videoContext: '',
				historyTime: 0,
				scTime: 0,
				num: '',
				pageInfo: {
					pageNum: 1,
					pageSize: 5,
					total: 0
				},
				pList: [],
				commentInfo: {
					title: '',
					number: 0,
					courseId: ''
				}
			}
		},
		created() {

			this.videoContext = document.getElementById('video')

		},
		// destroyed() {
		// 	var video = document.getElementById('videoPlayer')
		// 	video.pause()
		// },
		destroyed() {
			document.removeEventListener('visibilitychange', this.handleVisiable)
		},


		mounted() {
			document.addEventListener('visibilitychange', this.handleVisiable)
			this.getCourseInfo()
			this.getCourseEvaluateList()
		},
		methods: {
			handleVisiable(e) {
				switch (e.target.visibilityState) {
					case 'prerender':
						console.log('网页预渲染，内容不可见')
						break;
					case 'hidden':
						console.log('内容不可见，处理后台、最小化、锁屏状态')
						var video = document.getElementById('video')
						console.log(video, '视频')
						video.pause()
						break;
					case 'visible':
						console.log('处于正常打开')
						break;
				}
			},
			currentChange(val) {
				console.log(val)
				this.pageInfo.pageNum = val
				this.getCourseEvaluateList()
			},
			//评论接口数据
			getCourseEvaluateList() {
				HttpRequest.getRequest({
					method: "POST",
					data: {
						courseId: this.id,
						pageSize: this.pageInfo.pageSize,
						pageNum: this.pageInfo.pageNum,
					},
					url: "/app/api/course/getCourseEvaluateList"
				}).then(res => {
					this.pList = res.data.data.rows
					this.pageInfo.total = res.data.data.total
				})
			},
			//增加评论
			publishBtn() {
				this.commentInfo.courseId = this.id
				HttpRequest.getRequest({
					method: "POST",
					data: this.commentInfo,
					url: "/app/api/course/publishEvaluate"
				}).then(res => {
					if (res.data.code == 200) {
						Message.success('发布成功')
					}
					this.getCourseEvaluateList()
				})
			},
			setInitialTime() {
				this.$refs.videoPlayer.currentTime = this.playInfo.playTime;
			},
			playVideo(item) {
				console.log(item)
				let _that = this
				// _that.playInfo.videoUrl = item.videoUrl
				_that.playInfo = item
				_that.playState = 1
				_that.scTime = 0
				var video = document.getElementById('videoPlayer')
				video.currentTime = item.playTime
				_that.currentTime = item.playTime
				_that.historyTime = item.playTime
				video.pause()
				// video.seek(Number(item.playTime))



			},
			//视频播放进度
			playJindu(e) {
				const videoPlayer = this.$refs.videoPlayer;
				// const currentTime2 = videoPlayer.currentTime;
				// const duration2 = videoPlayer.duration;
				// const progress2 = (currentTime2 / duration2) * 100;
				// console.log(`当前播放进度：${progress2.toFixed(2)}%`);
				// console.log(e)
				// return
				let _that = this
				let currentTime = videoPlayer.currentTime.toFixed(0)
				if (_that.currentTime == 0) {

					_that.currentTime = currentTime
				} else {

				}
				if (currentTime - _that.historyTime > 2) {
					Message.error('不能快进哦')
					// let videoContext = document.getElementById("videoPlayer")
					videoPlayer.pause()
					videoPlayer.currentTime = _that.historyTime

				}
				_that.historyTime = currentTime;
				let duration = videoPlayer.duration.toFixed(0)
				let percentage = parseFloat(currentTime / duration * 100).toFixed(0)
				if (currentTime != 0 && _that.uploadShow == false && _that.courseInfo2.state != 0) {
					_that.num = setTimeout(function() {

						_that.time()
					}, _that.courseInfo2.num * 1000)

					// setInterval(_that.time(), _that.courseInfo2.num * 1000);
				}

				if (_that.playInfo.progress <= percentage) {
					//20秒上传一次
					if (_that.scTime == 0) {
						console.log("1111")
						//调用上传进度接口
						_that.scTime = Number.parseInt(currentTime) + 20
						_that.handleJinDu(currentTime, duration, percentage)
					}

					if (currentTime == duration) {
						console.log("22222")
						_that.handleJinDu(currentTime, duration, 100)
					}

					if (_that.scTime <= currentTime) {
						console.log("3333")
						//调用上传进度接口
						_that.scTime = Number.parseInt(currentTime) + 20
						_that.handleJinDu(currentTime, duration, percentage)
					}
					// 禁止拖动进度条快进

				}
			},
			//进度上传
			handleJinDu(currentTime, duration, percentage) {
				let _that = this
				console.log("_that.courseId:=>" + _that.courseId, "_that.playInfo:=>", _that.playInfo)
				HttpRequest.getRequest({
					method: "POST",
					data: {
						courseId: _that.id,
						videoId: _that.playInfo.videoId,
						currentTime: currentTime,
						duration: duration,
						subsectionId: _that.playInfo.id,
						percentage: percentage
					},
					url: "/app/api/course/playbackProgress"
				}).then(res => {
					console.log(res.data)
				});
			},
			getCourseInfo() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/course/getCourseInfo?courseId=" + this.id
				}).then(res => {
					this.courseInfo2 = res.data.data.appStuSettingRequest
					this.courseInfo = res.data.data.bfAppCourseVo
					if (this.courseInfo.keyword) {
						this.tags = this.courseInfo.keyword.split(',')
					}

					this.isCollect = res.data.data.collect
					this.list = res.data.data.list
					if (!this.playInfo.videoUrl) {
						this.playInfo = this.list[0]
						this.historyTime = this.list[0].playTime
					}
					this.setInitialTime()
					this.courseProgress = res.data.data.courseProgress
					this.commTitle = '评论' + '(' + this.courseInfo.comments + ')'
				});

			},
			//收藏操作
			handleColler() {
				let _that = this
				let type = 0
				if (_that.isCollect == 0) {
					type = 1
				}
				var data = {
					courseId: _that.id,
					type: type,
				}
				HttpRequest.getRequest({
					method: "POST",
					data: data,
					url: "/app/api/course/collectCourse"
				}).then(res => {
					_that.isCollect = type
				});
			},
			handleClick(e) {

			}
		}
	}
</script>

<style scoped lang="less">
	.tag_item {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		word-break: break-all;
	}

	video::-webkit-media-controls-timeline {
		display: none;
	}

	video::-webkit-media-controls-current-time-display {
		display: none;
	}

	video::-webkit-media-controls-time-remaining-display {
		display: none;
	}

	.collect_icon {
		width: 25px;
		margin-left: 13px;
	}

	.red {
		color: #E56B55;
	}

	.top_video {
		width: 100%;
		padding: 30px 0;
		background-color: #202329;
	}

	.video_top {
		padding: 20px;
	}

	.video_wrapper {
		width: 70%;
		margin: 0 auto;
		border-radius: 20px;
		background-color: #2D2D2D;
	}

	.video_tag {
		text-align: center;
		// width: 75px;
		max-width: 90px;
		height: 30px;
		padding: 0 10px;
		border-radius: 14px;
		color: #227BF9;
		margin-right: 13px;
		line-height: 30px;
		background-color: #E9F2FE;
	}

	.my_video {
		width: 100%;
	}

	.video_footer {
		display: flex;
		align-items: center;
		padding: 10px 20px;
		justify-content: space-between;
	}

	.video_footer p {
		color: #9A9A9A;
	}

	.join_wrapper {
		padding: 5px 10px;
		border-radius: 5px;
		background-color: #E9F2FE;
		color: #227BF9;
		display: flex;
		align-items: center;
		font-size: 12px;
		justify-content: space-between;
	}

	.join {
		background-color: #227BF9;
		color: #fff;
		width: 70px;
		height: 30px;
		line-height: 30px;
		font-size: 14px;
	}

	.con_cate {
		background-color: #fff;
		border-radius: 20px;
		width: 70%;
		margin: 26px auto;
	}

	.con_cate ::v-deep .el-tabs__active-bar {
		background-color: #227BF9;
	}

	.con_cate ::v-deep .el-tabs__item {
		font-size: 16px;
		padding: 0 40px;
		color: #666;
	}

	.con_cate ::v-deep .el-tabs__item.is-active {
		color: #227BF9;
	}

	.gengxin {
		padding: 8px 20px 14px;
		color: #999;
	}

	.video_item {
		border-top: 1px solid #F1F4F9;
		padding: 25px 10px;
		display: flex;
		align-items: center;
		color: #555555;
		justify-content: space-between;
	}

	.play_icon {
		width: 14px;
		margin: 0 10px;
	}

	.content {
		text-align: left;
		padding: 10px 20px;
	}

	.rate {
		display: flex;
		align-items: center;
		padding: 10px 20px;
		color: #666;
	}

	.con_cate ::v-deep .el-rate__icon {
		font-size: 20px;
	}

	.judge ::v-deep .el-textarea__inner {
		width: 96.6%;
		margin: 10px auto;
		background-color: #EFEFEF;
	}

	.judge_footer {
		display: flex;
		justify-content: flex-end;
		padding: 20px;
	}

	.publish {
		color: #fff;
		background-color: #227BF9;
		width: 110px;
		height: 43px;
		line-height: 43px;
		text-align: center;
		border-radius: 2px;
	}

	.judge_item {
		border-top: 1px solid #EEEEEE;
		padding: 20px;
		display: flex;
		// align-items: center;
	}

	.judge_con {
		text-align: left;
	}

	.avatar {
		width: 45px;
		height: 45px;
		border-radius: 50%;
		margin-right: 20px;
	}
</style>