<template>
  <div>
    //混入：
    <el-button @click="mixDemo">11</el-button>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <p>嘻嘻嘻</p>
    <router-link to="./Z2">跳转到z2</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>