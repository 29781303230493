<template>
  <div>
      Z2

      <p>哈哈哈</p>
      <p>哈哈哈</p>
      <p>哈哈哈</p>
      <p>哈哈哈</p>
      <p>哈哈哈</p>
      <p>哈哈哈</p>
      <p>哈哈哈</p>
      <p>哈哈哈</p> <p>哈哈哈</p>
       <p>哈哈哈</p>
        <p>哈哈哈</p>
         <p>哈哈哈</p> <p>哈哈哈</p> <p>哈哈哈</p>

     <router-link to='./Z'>跳转到Z页面</router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>