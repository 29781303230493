<template>
  <div>
    {{ file | guolv }}
    公共头部不变
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: 0,
    };
  },
  filters: {
    guolv(val){
      return val>0?'大于0':'小于等于0'
    }
  },
};
</script>

<style>
</style>