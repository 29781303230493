<template>
	<div>
		<div class="main">
			<el-carousel trigger="click" height="390px">
				<el-carousel-item v-for="item in swiperList" :key="item.id">
					<img :src="item.image" alt="" />
				</el-carousel-item>
			</el-carousel>
			<div class="books">
				<div @click="changCate(item.id,item.title)" :key="item.id" v-for="item in classList" class="book_item">
					<div class="flex hand align-center flex1">
						<img class="book_img" src="../../assets/icon/book.png" alt="" />
						{{item.title}}
					</div>
					<img class="book_right" src="../../assets/icon/right.png" alt="" />
				</div>
			</div>

		</div>
		<el-dialog top="22vh" width="23%" class="end_pop" :show-close="false" :visible.sync="popShow">
			<div class="pop_top">
				<span>{{title}}</span>
				<img @click="popShow=false" class="close" src="../../assets/icon/close.png" alt="" />
			</div>
			<div class="input_search2">
				<el-input @change="inputChange" placeholder="请输入关键词" suffix-icon="el-icon-search" v-model="keyword">
				</el-input>
			</div>
			<div class="book_wrapper">
				<div @click="goExam(item.id,item.title)" :key="item.id" v-for="item in filteredList" class="book_item2">
					<div class="flex hand align-center flex1">
						<img class="book_img" src="../../assets/icon/book.png" alt="" />
						{{item.title}}
					</div>
					<img class="book_right" src="../../assets/icon/right.png" alt="" />
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		HttpRequest
	} from '@/utils/http';

	import {
		mapState
	} from "vuex";
	import {
		Message
	} from "element-ui";
	export default {
		name: 'subjectList',
		data() {
			return {
				popShow: false,
				keyword: '',
				swiperList: [],
				classList: [],
				twoList: [], //二级分类
				title: ''
			}
		},
		mounted() {
			this.getInfo()
			this.getCate()
		},
		computed: {
			filteredList() {
				return this.twoList.filter((item) => {
					// 根据多种类型来搜索
					return item.title.indexOf(String(this.keyword)) !== -1
				})
			}
		},
		methods: {
			getInfo() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/home/getHomeInfo"
				}).then(res => {
					console.log(res.data.data)
					var data = res.data.data
					this.swiperList = data.carouselList

				});

			},
			changCate(e, title) {
				this.getTwo(e)
				this.title = title

			},
			getTwo(e) {
				HttpRequest.getRequest({
					method: "POST",
					url: '/app/api/examination/getExaminationTwoList?typeId=' + e,
				}).then(res => {
					console.log(res.data.data)
					this.twoList = res.data.data

					if (this.twoList.length == 0) {
						Message.error('没有更多内容了~')
					} else {
						this.popShow = true
					}
				});
			},
			getCate() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/examination/getExaminationOneList"
				}).then(res => {
					console.log(res.data.data)
					var data = res.data.data
					this.classList = data

				});
			},
			inputChange(e) {
				console.log(this.keyword)
			},
			goExam(id, e) {
				this.$router.push('../exHome?id=' + id + '&title=' + e);
			}
		}
	}
</script>

<style lang="less" scoped>
	.book_wrapper {
		min-height: 120px;
		max-height: 400px;
		overflow-y: hidden;
	}

	.main {
		width: 70%;
		margin: 20px auto;
	}

	::v-deep .el-carousel {
		border-radius: 8px;
	}

	::v-deep .el-carousel img {
		width: 100%;
		height: 100%;
		border-radius: 8px;
	}

	.books {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-height: 500px;
		overflow-y: scroll;
	}

	.book_item {
		width: 47%;
		padding: 0 15px;
		border-radius: 5px;
		background-color: #fff;
		margin: 10px 0;
		height: 64px;
		line-height: 64px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;


	}

	.book_img {
		width: 34px;
	}

	.book_right {
		width: 25px;
	}

	.book_item2 {
		width: 100%;
		border-radius: 5px;
		background-color: #fff;
		margin: 10px 0;
		height: 64px;
		line-height: 64px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.3);
		justify-content: space-between;
	}

	::v-deep .el-dialog__header {
		padding: 0;
	}

	::v-deep .el-dialog {
		border-radius: 5px;
	}

	::v-deep .el-dialog__body {
		padding: 15px 20px;
	}

	.pop_top {
		// padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;



		.close {
			width: 20px;
		}
	}

	.input_search2 {
		margin: 10px 0;
	}

	.input_search2 ::v-deep .el-input__inner {
		background-color: #F5F7FA;
		height: 44px;
		line-height: 44px;
	}

	.input_search2 ::v-deep .el-input__suffix {
		color: #227BF9;
	}

	.input_search2 .el-input__icon {
		font-size: 15px;
	}
</style>