<template>
	<div>
		<div class="main">
			<div class="main_con">
				<div class="flex align-center">
					<img class="avatar"
						:src="userInfo.headImage?userInfo.headImage:require('../assets/icon/avatar.png')" alt="" />
					<div>

						<p style="font-size: 20px;text-align: left;">{{userInfo.name}}</p>
						<p class="margin-top-sm">ID:{{userInfo.id}}</p>
					</div>
				</div>
				<div class="set" @click="goSet()">
					<img src="../../src/assets/icon/set.png" alt="" />
					设置
				</div>
			</div>
		</div>
		<div class="con">
			<div class="con_l">
				<div @click="changCate(index)" :key="index" v-for="(item,index) in cateList" class="cate_item"
					:style="cateIndex==index?'backgroundColor:#227BF9;color:#fff':''">
					<div class="flex align-center flex1">
						<img class="cate_icon" :src="cateIndex==index?item.icon2:item.icon" alt="" />
						<span>{{ item.name }}</span>
					</div>
					<img class="jt"
						:src="cateIndex==index?require('../../src/assets/icon/jt_bai@2x.png'):require('../../src/assets/icon/jt_hei.png')"
						alt="" />
				</div>
			</div>
			<div class="con_r">
				<p class="con_title" v-if="cateIndex!=6&&cateIndex!=7">
					{{ name }}
				</p>
				<div v-else class="con_title" style="border:none">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane v-if="cateIndex!=6" :label="cateIndex==6?'错题反馈':'意见反馈'" name="0"></el-tab-pane>
						<el-tab-pane label="我的反馈" name="1"></el-tab-pane>

					</el-tabs>
				</div>
				<div class="courses" v-if="cateIndex==0||cateIndex==5">
					<div v-for="item in list" class="course_item">
						<img :src="item.image" class="course_img" alt="" />
						<div class="shenglue course_item_title">{{ item.title }}</div>
						<div class="flex course_item_footer align-center">
							<div>共<span class="red">{{ item.chapterNumber }}</span>节课</div>
							<div>学习进度<span class="red">{{ item.percentage }}%</span></div>
						</div>
					</div>
				</div>
				<div class="my_exam" v-if="cateIndex==1">
					<div class="exam_item" v-for="item in examList">
						<div class="flex1" style="text-align: left;">
							<span class="sub_name">{{ item.title }}</span>
							<div class="exam_date">{{ item.endTime }}</div>
							<div class="flex align-center error_num">
								<img src="../../src/assets/icon/Group 3796@2x.png" alt="" />
								<span class="lv">{{ item.correct }}</span>
								<img src="../../src/assets/icon/Group 3797@2x.png" alt="" />
								<span class="red">{{ item.error }}</span>
							</div>
						</div>
						<div :class="score<80?'orange':'blue'"><span
								class="text-bold text-lg exam_num">{{ item.achievement }}</span><span>分</span></div>
					</div>
				</div>
				<div class="books" v-if="cateIndex==2">
					<div v-for="item in cuotiList" class="book_item">
						<div class="flex align-center flex1">
							<img class="book_img" src="../../src/assets/icon/book.png" alt="" />
							<span class="text-bold">{{ item.title }}</span>
						</div>
						<img class="book_right" src="../../src/assets/icon/right.png" alt="" />
					</div>
				</div>
				<div class="certificate" v-if="cateIndex==3">
					<div class="certificate_wrapper">
						<div class="zhengshu" v-for="item in certList">
							<img :src="item.certificateUrl" alt="" />
						</div>
					</div>
				</div>
				<div class="query" v-if="cateIndex==4">
					<span>复制网址到浏览器中打开，输入ID进行查询</span>
					<div class="href">
						<a href="http://selectcert.jaykt.cn">http://selectcert.jaykt.cn</a>
					</div>
					<div class="copy" @click="copyFun">复制网址</div>
				</div>
				<div class="feed" v-if="cateIndex==6||cateIndex==7">
					<div v-if="activeName==0&&cateIndex!=6">
						<el-input type="textarea" :rows="4" placeholder="您可以输入问题或建议，我们收到会及时跟进"
							v-model="submitInfo.content">
						</el-input>
						<div class="flex margin-top-sm pop_upload">


							<el-upload v-model="submitInfo.image"
								action="http://aqjy.shnxmyjt.com:8888/prod-api/app/api/my/upload" :headers="headerObj"
								list-type="picture-card" :on-preview="handlePictureCardPreview"
								:on-remove="handleRemove" :on-success="handleUploadSuccess">
								<i class="el-icon-plus"></i>
							</el-upload>

						</div>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
						<div class="publish" @click="feedbackFun">提交</div>
					</div>
					<div v-for="item in errorList" class="feed_item" v-else>
						<div class="flex align-center just-between" style="padding: 10px 0;">
							<div class="text-gray">{{item.createTime}}</div>
							<div v-if="cateIndex==6" :class="item.relyContent==null?'text-gray':'blue'">
								{{ item.relyContent==null ? '等待回复' : '已回复' }}
							</div>
							<div v-if="cateIndex==7" :class="item.remark==null?'text-gray':'blue'">
								{{ item.remark==null ? '等待回复' : '已回复' }}
							</div>
						</div>
						<div class="">{{item.content}}</div>

						<div class="flex align-center" style="flex-wrap: wrap;">
							<img class="feed_img" v-if="item.image" v-for="item in item.image.split(',')" :src="item"
								alt="" />
						</div>
						<div class="feed_foot" v-if="cateIndex==6">
							<span class="text-gray" v-if="item.relyContent">平台回复:</span>
							<div>
								{{item.relyContent}}
							</div>
						</div>
						<div class="feed_foot" v-if="cateIndex==7">
							<span class="text-gray" v-if="item.remark">平台回复:</span>
							<div>
								{{item.remark}}
							</div>
						</div>
					</div>
				</div>
				<div class="about" v-if="cateIndex==8" style="text-align: left">
					<span style="white-space:pre-wrap;"> {{richText}}</span>
				</div>
				<div class="contact" v-if="cateIndex==9">
					<div class="flex align-center just-between">
						<span>联系电话</span>
						<span>{{telInfo.phone}}</span>
					</div>
					<img class="ewm" :src="telInfo.erweimaUrl" alt="" />
					<!-- <div class="margin-top-sm">扫描二维码</div> -->
				</div>
				<div v-if="cateIndex==10">
					<div class="form_item">
						<div class="form_title">证书名称</div>
						<div class="flex1">
							<el-input v-model="form.typeWork" placeholder="请输入证书名称"></el-input>
						</div>
					</div>
					<div class="form_item">
						<div class="form_title">证书编号</div>
						<div class="flex1">
							<el-input v-model="form.idNumber" placeholder="请输入证书编号"></el-input>
						</div>
					</div>
					<div class="form_item">
						<div class="form_title">证书分类</div>
						<div class="flex1">
							<el-select style="width:100%" v-model="form.certType" placeholder="请选择">
								<el-option @change="typeChange" placeholder="请选择证书分类"
									v-for="item in CertificateTypeList" :key="item.id" :label="item.title"
									:value="item.id">
								</el-option>
							</el-select>
						</div>

					</div>
					<div class="form_item">
						<div class="form_title">证书工种</div>
						<div class="flex1">
							<el-select style="width:100%" v-model="form.certTypework" placeholder="请选择">
								<el-option placeholder="请选择证书工种" v-for="item in workList" :key="item.id"
									:label="item.title" :value="item.id">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="form_item">
						<div class="form_title">发证部门</div>
						<div class="flex1">
							<el-input v-model="form.grantDept" placeholder="请输入发证部门"></el-input>
						</div>
					</div>
					<div class="form_item">
						<div class="form_title">取证日期</div>
						<div class="flex1">
							<el-date-picker :editable="false" value-format="yyyy-MM-dd" style="width: 100%;"
								v-model="form.qzTime" type="date" placeholder="请选择取证日期">
							</el-date-picker>
						</div>
					</div>
					<div class="form_item">
						<div class="form_title">有限日期</div>
						<div class="flex1">
							<el-date-picker :editable="false" value-format="yyyy-MM-dd" style="width: 100%;"
								v-model="form.yxTime" type="date" placeholder="请选择有限日期">
							</el-date-picker>
						</div>
					</div>
					<div class="form_item">
						<div class="form_title">下次复审日期</div>
						<div class="flex1">
							<el-date-picker :editable="false" value-format="yyyy-MM-dd" style="width: 100%;"
								v-model="form.fsTime" type="date" placeholder="请选择下次复审日期">
							</el-date-picker>
						</div>
					</div>
					<div class="form_item">
						<span style="width: 120px;"></span>
						<el-upload v-model="form.image"
							action="http://aqjy.shnxmyjt.com:8888/prod-api/app/api/my/upload" :headers="headerObj"
							list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
							:on-success="handleUploadSuccess2">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
					<div class="form_item">
						<span style="width: 120px;"></span>
						<div class="docuBtn hand" @click="submitDocu()">提交</div>
					</div>
				</div>
				<el-pagination v-if="cateIndex!=2 &&cateIndex!=4 && pageInfo.total>0" @current-change="currentChange"
					background layout="prev, pager, next" :total="pageInfo.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		HttpRequest
	} from '@/utils/http';
	import {
		Message
	} from 'element-ui';

	export default {
		name: 'mine',
		data() {
			return {
				CertificateTypeList: [], //证件分类
				workList: [], //工种
				form: {
					typeWork: '',
					idNumber: '',
					image: '',
					certType: '',
					certTypework: '',
					grantDept: '', //发证部门
					qzTime: '',
					yxTime: '',
					fsTime: ''

				},
				userInfo: JSON.parse(localStorage.getItem("userInfo")),
				richText: '',
				headerObj: {
					'appToken': localStorage.getItem("token")
				},
				errorList: [],
				collectList: [],
				certList: [],
				cuotiList: [],
				list: [],
				examList: [],
				status: 0, //反馈是否回复
				activeName: 0,
				score: 70,
				name: '学习记录',
				cateIndex: 0,
				dialogImageUrl: '',
				dialogVisible: false,
				cateList: [{
						name: '学习记录',
						icon: require('../../src/assets/icon/Group 4163@2x.png'),
						icon2: require('../../src/assets/icon/Group 4162@2x.png'),
					},
					{
						name: '我的考试',
						icon: require('../../src/assets/icon/Group 4144@2x.png'),
						icon2: require('../../src/assets/icon/Group 4155@2x.png'),
					},
					{
						name: '我的错题',
						icon: require('../../src/assets/icon/Group 4145@2x.png'),
						icon2: require('../../src/assets/icon/Group 4154@2x.png'),
					},
					{
						name: '我的证书',
						icon: require('../../src/assets/icon/Group 4146@2x.png'),
						icon2: require('../../src/assets/icon/Group 4156@2x.png'),
					},
					{
						name: '证书查询',
						icon: require('../../src/assets/icon/Group 4147@2x.png'),
						icon2: require('../../src/assets/icon/Group 4157@2x.png'),
					},
					{
						name: '我的收藏',
						icon: require('../../src/assets/icon/Group 4148@2x.png'),
						icon2: require('../../src/assets/icon/Group 4153@2x.png'),
					},
					{
						name: '错题反馈',
						icon: require('../../src/assets/icon/Group 4149@2x.png'),
						icon2: require('../../src/assets/icon/Group 4158@2x.png'),
					},
					{
						name: '意见反馈',
						icon: require('../../src/assets/icon/Group 4150@2x.png'),
						icon2: require('../../src/assets/icon/Group 4159@2x.png'),
					},
					{
						name: '关于我们',
						icon: require('../../src/assets/icon/Group 4151@2x.png'),
						icon2: require('../../src/assets/icon/Group 4160@2x.png'),
					},
					{
						name: '联系我们',
						icon: require('../../src/assets/icon/Group 4152@2x.png'),
						icon2: require('../../src/assets/icon/Group 4161@2x.png'),
					},
					{
						name: '个人证件',
						icon: require('../../src/assets/icon/icon11.png'),
						icon2: require('../../src/assets/icon/icon12.png'),
					}
				],
				pageInfo: {
					pageNum: 1,
					pageSize: 10,
					total: 0
				},
				submitInfo: {
					content: '',
					image: ''
				},
				telInfo: {},
			}
		},
		created() {
			console.log(this.userInfo)
			this.changCate(0);
		},
		mounted() {
			if (this.$route.query.index) {
				this.cateIndex = this.$route.query.index
				this.getList(this.cateIndex)
				this.name = this.cateList[this.cateIndex].name
			}
		},
		methods: {
			typeChange(e) {
				console.log(e)
			},
			changCate(e) {
				this.cateIndex = e
				this.pageInfo.pageNum = 1;
				this.pageInfo.total = 0;
				console.log(e)
				this.name = this.cateList[e].name
				this.getList(e)
			},
			currentChange(val) {
				console.log(val)
				this.pageInfo.pageNum = val
				this.getList(this.cateIndex)
			},
			getList(e) {
				if (e == 0) {
					//学习记录 /app/api/my/getLearningRecord
					this.stuLogFun();
				}
				if (e == 1) {
					//我的考试
					this.myExamFun();
				}
				if (e == 2) {
					//我的错题
					this.myWrongQuestionFun();
				}
				if (e == 3) {
					//我的错题
					this.myCertFun();
				}
				if (e == 5) {
					//我的错题
					this.myCollectFun();
				}
				if (e == 6) {
					//错题反馈列表
					this.errorFeedbackFun();
				}
				if (e == 8) {
					//错题反馈列表
					this.aboutUsFun();
				}
				if (e == 9) {
					//联系我们
					this.telWeFun();
				}
				if (e == 10) {
					//个人证件
					this.getCertificateType()
					this.getWork()
				}

			},
			//证书分类
			getCertificateType() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/my/getCertificateType"
				}).then(res => {
					this.CertificateTypeList = res.data.data
				}).catch(err => {});
			},
			//证书工种
			getWork() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/my/getCertificateTypework"
				}).then(res => {
					this.workList = res.data.data
				}).catch(err => {});
			},
			//学习记录
			stuLogFun() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/my/getLearningRecord",
					data: this.pageInfo
				}).then(res => {
					console.log(res.data)
					this.list = res.data.data.rows
					this.pageInfo.total = res.data.data.total
				}).catch(err => {});
			},
			//我的考试
			myExamFun() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/examination/getExamRecordList",
					data: this.pageInfo
				}).then(res => {
					console.log(res.data)
					this.examList = res.data.data.rows
					this.pageInfo.total = res.data.data.total
				}).catch(err => {});
			},
			submitDocu() {
				console.log(this.form)
				if (this.form.typeWork == '') {
					this.$message({
						message: '请输入证书名称',
						type: 'error'
					});
					return
				}
				if (this.form.idNumber == '') {
					this.$message({
						message: '请输入证书号码',
						type: 'error'
					});
					return
				}
				if (this.form.certType == '') {
					this.$message({
						message: '请选择证书分类',
						type: 'error'
					});
					return
				}
				if (this.form.grantDept == '') {
					this.$message({
						message: '请输入发证部门',
						type: 'error'
					});
					return
				}
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/my/updateDocument",
					data: this.form
				}).then(res => {
					if (res.data.code == 200) {
						this.$message({
							message: '提交成功',
							type: 'success'
						});
						this.cateIndex = 0
					}

				}).catch(err => {});
			},
			myWrongQuestionFun() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/examination/getOneWrongRecordList"
				}).then(res => {
					console.log(res.data)
					this.cuotiList = res.data.data
				}).catch(err => {});
			},
			myCertFun() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/my/getMyCertificate"
				}).then(res => {
					console.log(res.data)
					this.certList = res.data.data
				}).catch(err => {});
			},
			copyFun() {
				// 获取要复制到剪切板的链接
				const link = "http://selectcert.jaykt.cn";
				// 创建临时 <textarea> 元素，将链接写入其中
				const textArea = document.createElement("textarea");
				textArea.value = link;
				document.body.appendChild(textArea);
				// 选中并复制链接
				textArea.select();
				document.execCommand("copy");
				// 将 <textarea> 元素删除
				document.body.removeChild(textArea);
				this.$message({
					message: '复制成功',
					type: 'success'
				});

			},
			myCollectFun() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/my/getMyCollection",
					data: this.pageInfo
				}).then(res => {
					console.log(res.data)
					this.list = res.data.data.rows
					this.pageInfo.total = res.data.data.total
				}).catch(err => {});
			},
			//错题反馈
			errorFeedbackFun() {

				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/my/getWrongQuestionFeedbackList"
				}).then(res => {
					console.log(res.data)
					this.errorList = res.data.data
				}).catch(err => {});
			},
			handleClick() {
				console.log(this.activeName)
				console.log(this.activeName == 1 && this.cateIndex == 7)
				if (this.activeName == 1 && this.cateIndex == 7) {
					this.feedbackListFun()
				}
			},
			//提交意见反馈
			feedbackFun() {
				if (this.submitInfo.content == null || this.submitInfo.content == undefined || this.submitInfo.content ==
					'') {
					this.$message({
						message: '反馈内容不得为空',
						type: 'error'
					});
					return
				}
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/my/sumbitFeedback",
					data: this.submitInfo,
				}).then(res => {
					console.log(res.data)
				}).catch(err => {});
			},
			//反馈列表
			feedbackListFun() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/my/getFeedbackList",
				}).then(res => {
					console.log(res.data)
					this.errorList = res.data.data
				}).catch(err => {});
			},
			//联系我们
			telWeFun() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/api/my/getContactUs",
				}).then(res => {
					console.log(res.data.data)
					this.telInfo = res.data.data
				}).catch(err => {});
			},
			//上传图片
			handlePictureCardPreview(val) {
				console.log(val)

			},
			handleRemove() {

			},
			//上传成功
			handleUploadSuccess(val) {
				console.log(val.data.url, val.code)
				console.log(this.submitInfo.image)
				if (val.code === 200) {
					if (this.submitInfo.image == '') {
						this.submitInfo.image = val.data.url
					} else {
						this.submitInfo.image = this.submitInfo.image + "," + val.data.url
					}

				}
			},
			handleUploadSuccess2(val) {
				console.log(val)
				if (val.code === 200) {
					if (this.form.image == '') {
						this.form.image = val.data.url
					} else {
						this.form.image = this.form.image + "," + val.data.url
					}

				}
			},
			//关于我们
			aboutUsFun() {
				HttpRequest.getRequest({
					method: "POST",
					url: "/app/login/getOther?type=1",
				}).then(res => {
					console.log(res.data)
					this.richText = res.data.data.richText
				}).catch(err => {});
			},

			goSet() {
				this.$router.push('../Set');
			}
		}
	}
</script>

<style lang="less" scoped>
	.docuBtn {
		background-color: #227BF9;
		height: 40px;
		width: 200px;
		text-align: center;
		color: #fff;
		line-height: 40px;
		border-radius: 30px;
	}

	.form_item {
		display: flex;
		align-items: center;
		width: 70%;
		margin: 15px 0;

		.form_title {
			width: 120px;
		}

	}

	.feed_item {
		text-align: left;
		margin: 10px 0;
		background-color: #F9FAFC;
		border-radius: 6px;
		padding: 10px 20px;


		.feed_img {
			width: 70px;
			height: 70px;
			border-radius: 5px;
			margin: 10px 10px 10px 0;
		}

		.feed_foot {
			padding: 15px 0;
			line-height: 30px;
			border-top: 1px solid #EFF3FB;
		}
	}

	.contact {
		width: 50%;
		margin: 40px auto;
		text-align: center;

		.ewm {
			width: 300px;
			height: 340px;
			margin-top: 50px;
		}
	}

	.main {
		display: flex;
		width: 100%;
		height: 250px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-image: url('../assets/img/mine_bg.png');

		.main_con {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 70%;
			margin: 0 auto;
			padding: 20px 0;
			color: #fff;

			.avatar {
				width: 80px;
				height: 80px;
				border-radius: 50%;
				margin-right: 15px;
			}

			.set {
				border: 1px solid #fff;
				border-radius: 4px;
				padding: 5px 15px;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 17px;
					margin-right: 5px;
				}
			}
		}
	}

	.con {
		display: flex;
		justify-content: space-between;
		margin: 20px auto;
		width: 70%;

		.con_l {
			background-color: #fff;
			border-radius: 5px;
			padding: 5px;
			width: 21%;
			max-height: 700px;

			.cate_item {
				margin: 10px 5px;
				display: flex;
				border-radius: 5px;
				padding: 15px 20px;
				align-items: center;
				justify-content: space-between;
			}

			.cate_icon {
				margin-right: 5px;
				width: 23px;
			}

			.jt {
				width: 20px;
			}
		}

		.con_r {
			background-color: #fff;
			border-radius: 5px;
			padding: 5px 14px;
			width: 74%;
			max-height: 800px;
			overflow-y: scroll;

			.con_title {
				text-align: left;
				padding: 14px 0;
				border-bottom: 1px solid #F1F1F1;
			}

			.courses {
				margin: 20px auto;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}

			.course_item {
				border: 1px solid #DDDDDD;
				border-radius: 7px;
				color: black;
				flex: 0 0 32.4%;
				/* height: 30px; */
				text-align: center;
				/* line-height: 30px; */
				background-color: white;
				/* 边距懒得算，css函数代替 */
				margin-right: calc(3% / 3);
				margin-bottom: calc(3% / 3);
			}

			.course_img {
				border-radius: 7px;
				width: 100%;
				height: 186px;
			}

			.course_item_footer {
				justify-content: space-between;
				color: #999;
				margin: 10px 20px;
			}

			.red {
				color: #E56B55;
			}

			/* 去除每行尾的多余边距 */

			.course_item:nth-child(3n) {
				margin-right: 0;
			}

			/* 使最后一个元素的边距填满剩余空间 */

			.course_item:last-child {
				margin-right: auto;
			}

			.course_item_title {
				height: 52px;
				overflow: hidden;
				text-align: left;
				padding: 10px 20px;
			}
		}
	}

	.my_exam {
		.exam_item {
			background-color: #F1F4F9;
			display: flex;
			margin: 15px 0;
			border-radius: 5px;
			padding: 15px;
			align-items: center;
			justify-content: space-between;

			.sub_name {
				font-size: 19px;
			}

			.exam_date {
				color: #999;
				margin: 10px 0;
				font-size: 14px;
			}

			.error_num {
				span {
					font-size: 14px;
				}
			}

			img {
				width: 14px;
			}

			.lv {
				color: #57E1C5;
				margin: 0 7px;
			}

			.red {
				margin: 0 7px;
			}

			.exam_num {
				font-size: 25px;
				margin-right: 5px;
			}

			.orange {
				color: #FF9900;


			}
		}
	}

	.books {

		.book_item {
			padding: 10px 15px;
			border-radius: 5px;
			background-color: #fff;
			margin: 10px 0;
			height: 64px;
			line-height: 64px;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border: 1px solid #DDDDDD;

		}

		.book_img {
			width: 46px;
			margin-right: 10px;
		}

		.book_right {
			width: 27px;
		}

		.book_item2 {
			width: 100%;
			border-radius: 5px;
			background-color: #fff;
			margin: 10px 0;
			height: 64px;
			line-height: 64px;
			border-radius: 5px;
			display: flex;
			align-items: center;
			box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.3);
			justify-content: space-between;
		}
	}

	.certificate {
		.certificate_wrapper {

			margin: 20px auto;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.zhengshu {
				flex: 0 0 32.4%;
				/* height: 30px; */
				text-align: center;
				/* line-height: 30px; */
				background-color: white;
				/* 边距懒得算，css函数代替 */
				margin-right: calc(3% / 3);
				margin-bottom: calc(3% / 3);
			}

			img {
				width: 100%;
				height: 100%;
			}

			/* 去除每行尾的多余边距 */

			.zhengshu:nth-child(3n) {
				margin-right: 0;
			}

			/* 使最后一个元素的边距填满剩余空间 */

			.zhengshu:last-child {
				margin-right: auto;
			}
		}
	}

	.query {
		padding: 50px 0;

		span {
			color: #666;
		}

		.href {
			background-color: #F9FAFC;
			text-align: center;
			width: 50%;
			margin: 20px auto;
			height: 50px;
			line-height: 50px;
			color: #227BF9;

		}

		a {
			color: #227BF9;
		}

		.copy {
			background-color: #227BF9;
			color: #fff;
			border-radius: 5px;
			width: 130px;
			line-height: 40px;
			height: 40px;
			text-align: center;
			margin: 10px auto;
		}
	}

	::v-deep .el-tabs__active-bar {
		background-color: #227BF9;
	}

	::v-deep .el-tabs__item.is-active {
		color: #000;
	}

	::v-deep .el-tabs__item {
		height: 46px;
		line-height: 46px;
		font-size: 16px;
	}

	.feed {
		.publish {
			background-color: #227BF9;
			border-radius: 23px;
			width: 165px;
			margin: 20px 0;
			height: 43px;
			line-height: 43px;
			color: #fff;
			text-align: center;
		}
	}
</style>